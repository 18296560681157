import { createStore } from "vuex";
import auth from "./modules/auth";

// Create store
export default createStore({
  modules: {
    auth
  }
});

export const useAuthStore = () => {
  return auth;
};
