<template>
    <!-- Header -->
    <div class="header">

        <!-- Logo -->
        <div class="header-left active">
            <router-link to="/dashboard" class="logo logo-normal">
                <img src="@/assets/img/logo.png" alt="">
            </router-link>
            <router-link to="/dashboard" class="logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="">
            </router-link>
            <router-link to="/dashboard" class="logo-small">
                <img src="@/assets/img/logo-small.png" alt="">
            </router-link>
            <a id="toggle_btn" href="javascript:void(0);" @click="toggleSidebar">
                <vue-feather type="chevrons-left"></vue-feather>
            </a>
        </div>
        <!-- /Logo -->

        <a id="mobile_btn" class="mobile_btn" href="javascript:void(0);" @click="toggleSidebar1">
            <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </a>

        <!-- Header Menu -->
        <ul class="nav user-menu">

            <!-- Search -->
            <li class="nav-item nav-searchinputs">
                <div class="top-nav-search">
                    <a href="javascript:void(0);" class="responsive-search">
                        <i class="fa fa-search"></i>
                    </a>
                    <form action="javascript:void(0);" class="dropdown">
                        <div class="searchinputs dropdown-toggle" id="dropdownMenuClickable" data-bs-toggle="dropdown"
                            data-bs-auto-close="false">
                            <input type="text" :placeholder="$t('search')">
                            <div class="search-addon">
                                <span><vue-feather type="x-circle" class="feather-14"></vue-feather></span>
                            </div>
                        </div>
                        <div class="dropdown-menu search-dropdown" aria-labelledby="dropdownMenuClickable">
                            <div class="search-info">
                                <h6><span><vue-feather type="search" class="feather-16"></vue-feather></span>Recent Searches
                                </h6>
                                <ul class="search-tags">
                                    <li><a href="javascript:void(0);">Products</a></li>
                                    <li><a href="javascript:void(0);">Sales</a></li>
                                    <li><a href="javascript:void(0);">Applications</a></li>
                                </ul>
                            </div>
                            <div class="search-info">
                                <h6><span><vue-feather type="help-circle" class="feather-16"></vue-feather></span>Help</h6>
                                <p>How to Change Product Volume from 0 to 200 on Inventory management</p>
                                <p>Change Product Name</p>
                            </div>
                            <div class="search-info">
                                <h6><span><vue-feather type="user" class="feather-16"></vue-feather></span>Customers</h6>
                                <ul class="customers">
                                    <li>
                                        <a href="javascript:void(0);">Aron Varu<img src="@/assets/img/profiles/avator1.jpg"
                                                alt="" class="img-fluid"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">Jonita<img src="@/assets/img/profiles/avatar-01.jpg"
                                                alt="" class="img-fluid"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">Aaron<img src="@/assets/img/profiles/avatar-10.jpg"
                                                alt="" class="img-fluid"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </li>
            <!-- /Search -->


            <li v-if="hasDirectRepairPermission" class="nav-item nav-item-pos" :title="$t('direct_repair')">
                <router-link to="/repair/direct_repair">
                    <vue-feather type="tablet"></vue-feather>
                </router-link>
            </li>

            <li v-if="hasPosPermission" class="nav-item nav-item-pos" title="POS">              <router-link to="/sales/pos">
                    <vue-feather type="shopping-cart"></vue-feather>
                </router-link>
            </li>

            <!-- Select Store -->
            <li class="nav-item dropdown has-arrow main-drop select-store-dropdown">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link select-store" data-bs-toggle="dropdown">
                    <span class="user-info">
                        <span class="user-detail">
                            <span class="user-name">{{ getWarehouseTitle }}</span>
                        </span>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a 
                        v-for="warehouse in warehouses" 
                        :key="warehouse.id" 
                        href="javascript:void(0);" 
                        class="dropdown-item" 
                        @click="setDefaultWarehouse(warehouse.id)"
                    >
                        {{ warehouse.name }} <!-- Assuming warehouse has a 'name' property -->
                    </a>
                </div>
            </li>
            <!-- /Select Store -->

            <!-- Flag -->
            <li class="nav-item dropdown has-arrow flag-nav nav-item-box">
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);" role="button">
                    <img :src="getFlagImage" alt="Language" class="img-fluid">
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a href="javascript:void(0);" class="dropdown-item" :class="{ active: currentLanguage === 'en' }" @click="changeLanguage('en')">
                        <img src="@/assets/img/flags/us.png" alt="" height="16"> English
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item" :class="{ active: currentLanguage === 'fr' }" @click="changeLanguage('fr')">
                        <img src="@/assets/img/flags/fr.png" alt="" height="16"> French
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item" :class="{ active: currentLanguage === 'it' }" @click="changeLanguage('it')">
                        <img src="@/assets/img/flags/it.png" alt="" height="16"> Italian
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item" :class="{ active: currentLanguage === 'de' }" @click="changeLanguage('de')">
                        <img src="@/assets/img/flags/de.png" alt="" height="16"> German
                    </a>
                </div>
            </li>
            <!-- /Flag -->

            <li class="nav-item nav-item-box">
                <a href="javascript:void(0);" id="btnFullscreen" @click="initFullScreen">
                    <vue-feather type="maximize"></vue-feather>
                </a>
            </li>
          
            <!-- Notifications -->
            <li class="nav-item dropdown nav-item-box">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                    <vue-feather type="bell"></vue-feather>
                    <span v-if="notifs>0" class="badge rounded-pill">{{ notifs }}</span>
                </a>
                <div class="dropdown-menu notifications">
                    <div class="topnav-dropdown-header">
                        <span class="notification-title">Notifications</span>
                        <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                    </div>
                    <div class="noti-content">
                        <ul class="notification-list">
                            <li class="notification-message">
                                <router-link to="/activities">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-02.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">John Doe</span> added
                                                new task <span class="noti-title">Patient appointment booking</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">4 mins ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/activities">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-03.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Tarah Shropshire</span>
                                                changed the task name <span class="noti-title">Appointment booking
                                                    with payment gateway</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">6 mins ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/activities">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-06.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Misty Tison</span>
                                                added <span class="noti-title">Domenic Houston</span> and <span
                                                    class="noti-title">Claire Mapes</span> to project <span
                                                    class="noti-title">Doctor available module</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">8 mins ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/activities">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-17.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Rolland Webber</span>
                                                completed task <span class="noti-title">Patient and Doctor video
                                                    conferencing</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">12 mins ago</span></p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/activities">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-13.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Bernardo Galaviz</span>
                                                added new task <span class="noti-title">Private chat module</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">2 days ago</span></p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="topnav-dropdown-footer">
                        <router-link to="/activities">View all Notifications</router-link>
                    </div>
                </div>
            </li>
            <!-- /Notifications -->

            <!-- <li class="nav-item nav-item-box">
                <router-link to="/settings/general-settings"><vue-feather type="settings"></vue-feather></router-link>
            </li> -->
            <li class="nav-item dropdown has-arrow main-drop">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                    <span class="user-info">
                        <span class="user-letter">
                            <img :src="`${publicUrl}images/avatar/${user.avatar}`" alt="" class="img-fluid">
                        </span>
                        <span class="user-detail">
                            <span class="user-name">{{ user.username }}</span>
                            <!-- <span class="user-role">Super Admin</span> -->
                        </span>
                    </span>
                </a>
                <div class="dropdown-menu menu-drop-user">
                    <div class="profilename">
                        <!-- <div class="profileset">
                            <span class="user-img">
                                <img :src="`${publicUrl}images/avatar/${user.avatar}`" alt="">
                                <span class="status online"></span> 
                            </span>
                            <div class="profilesets">
                                <h6>{{ user.username }}</h6>
                            </div>
                        </div> -->
                        <hr class="m-0">
                        <router-link class="dropdown-item" to="/pages/profile"> <vue-feather class="me-2"
                                type="user"></vue-feather> My
                            Profile</router-link>
                        <router-link v-if="hasSettingSystem" class="dropdown-item" to="/settings/general-settings"><vue-feather class="me-2"
                                type="settings"></vue-feather>Settings</router-link>
                        <hr class="m-0">
                        <a class="dropdown-item logout pb-0" href="javascript:void(0);" @click="handleLogout">
                            <img src="@/assets/img/icons/log-out.svg"
                                class="me-2" alt="img">
                                Logout</a>
                    </div>
                </div>
            </li>
        </ul>
        <!-- /Header Menu -->

        <!-- Mobile Menu -->
        <div class="dropdown mobile-user-menu">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
            <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" to="/pages/profile">My Profile</router-link>
                <router-link v-if="hasSettingSystem" class="dropdown-item" to="/settings/general-settings">Settings</router-link>
                <router-link class="dropdown-item" to="/">Logout</router-link>
            </div>
        </div>
        <!-- /Mobile Menu -->
    </div>
    <!-- /Header -->

    <side-settings></side-settings>
</template>

<script>
import { useAuthStore } from  '@/store'; 
import { mapActions  } from 'vuex'


export default {
    data() {
        return {
            currentLanguage: this.$i18n.locale,
            user: {
                username: '',
                avatar: '',
            },
            permissions:[] , 
            warehouses:[] , 
            selectedWarehouseId:'',
            publicUrl: process.env.VUE_APP_API_BASE_URL || '', // Add this line to get the public path
            hasDirectRepairPermission: false, 
            hasPosPermission: false, 
            hasSettingSystem: false, 
            notifs:0,
        };
    },
    mounted() {
        this.initMouseoverListener();
        this.currentLanguage = this.$i18n.locale;
        this.loadUserData();
    },
    methods: {
        ...mapActions(['logout','setSelectedWarehouseId']),
        loadUserData() {
            const authStore = useAuthStore();
            const userData = authStore.state.user;
            this.user = {
                username: userData.username || 'Guest User',
                avatar  : userData.avatar || null,
            };
            this.permissions = authStore.state.Permissions || []; // Assuming permissions are stored in userData
            this.warehouses  = authStore.state.warehouses;
            this.selectedWarehouseId  = authStore.state.selectedWarehouseId;
            // if(this.selectedWarehouseId){
            //     this.setDefaultWarehouse(this.selectedWarehouseId);
            // }
            this.notifs  = authStore.state.notifs;
            this.checkPermission(); // Check for direct_repair permission
        },
        setDefaultWarehouse(warehouseId){
               //------- det default in auth 
               const warehouse = this.warehouses.find((item) => item.id == warehouseId);
               this.$store.commit('setSelectedWarehouseId',warehouseId);
               this.selectedWarehouseId = warehouseId;
               // refresh content
               // his.$router.go(0);
        },
        checkPermission() {
            this.hasDirectRepairPermission = this.permissions.includes('direct_repair');
            this.hasPosPermission = this.permissions.includes('Pos_view');
            this.hasSettingSystem = this.permissions.includes('setting_system');
        },
        toggleSidebar1() {
            const body = document.body;
            body.classList.toggle("slide-nav");
        },
        toggleSidebar() {
            const body = document.body;
            body.classList.toggle("mini-sidebar");
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },

        initMouseoverListener() {
            document.addEventListener('mouseover', this.handleMouseover);
        },
        handleMouseover(e) {
            e.stopPropagation();

            const body = document.body;
            const toggleBtn = document.getElementById('toggle_btn');

            if (body.classList.contains('mini-sidebar') && this.isElementVisible(toggleBtn)) {
                const target = e.target.closest('.sidebar, .header-left');

                if (target) {
                    body.classList.add('expand-menu');
                    this.slideDownSubmenu();
                } else {
                    body.classList.remove('expand-menu');
                    this.slideUpSubmenu();
                }

                e.preventDefault();
            }
        },
        isElementVisible(element) {
            return element.offsetWidth > 0 || element.offsetHeight > 0;
        },
        slideDownSubmenu() {
            const subdropPlusUl = document.getElementsByClassName('subdrop');
            for (let i = 0; i < subdropPlusUl.length; i++) {
                const submenu = subdropPlusUl[i].nextElementSibling;
                if (submenu && submenu.tagName.toLowerCase() === 'ul') {
                    submenu.style.display = 'block';
                }
            }
        },
        slideUpSubmenu() {
            const subdropPlusUl = document.getElementsByClassName('subdrop');
            for (let i = 0; i < subdropPlusUl.length; i++) {
                const submenu = subdropPlusUl[i].nextElementSibling;
                if (submenu && submenu.tagName.toLowerCase() === 'ul') {
                    submenu.style.display = 'none';
                }
            }
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang; // Change the language
            this.currentLanguage = lang; // Update the current language
            localStorage.setItem('lang' ,lang);
            this.$router.go(0);
            // location.reload();
        },
        async handleLogout() {
            await this.$store.commit('logout');
            setTimeout(() => {
                window.location.href = "/signin"; // Redirect to signin after logout
            }, 100);
            // this.logout();
            // const authStore = useAuthStore();
            // await authStore.mutation.logout(); // Call the logout mutation
        },
    },
    beforeUnmount() {
        document.removeEventListener('mouseover', this.handleMouseover);
    },
    computed: {
        getWarehouseTitle(){
            const warehouse = this.warehouses.find((item) => item.id == this.selectedWarehouseId);
            if(warehouse)
               return warehouse.name ; 
            return 'All Warehouses';
        },
        getFlagImage() {
            switch (this.currentLanguage) {
                case 'en':
                    return require('@/assets/img/flags/us.png');
                case 'fr':
                    return require('@/assets/img/flags/fr.png');
                case 'it':
                    return require('@/assets/img/flags/it.png');
                case 'de':
                    return require('@/assets/img/flags/de.png');
                default:
                    return require('@/assets/img/flags/us.png'); // Default to English flag
            }
        }
    },
};
</script>