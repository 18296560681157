import en from './locales/en';
import fr from './locales/fr';
import de from './locales/de';
import it from './locales/it';

export default {
  en,
  fr,
  de,
  it
};
