<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper bg-img">
        <div class="login-content">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" class="w-50">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="@/assets/img/logo.png" alt="img" />
              </div>
              <router-link to="/dashboard" class="login-logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Sign In</h3>
              </div>
              <div class="form-login mb-3">
                <label class="form-label">Username</label>
                <div class="form-addons">
                  <Field
                    name="username"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.username }"
                    autocomplete="username"
                  />
                  <div class="invalid-feedback">{{ errors.username }}</div>
                  <div class="usernameshow text-danger" id="username"></div>
                  <img src="@/assets/img/icons/user-icon.svg" alt="img"   />
                </div>
              </div>
              <div class="form-login mb-3">
                <label class="form-label">Password</label>
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.password }"
                    autocomplete="current-password"
                  />
                  <span @click="toggleShow" class="toggle-password" style="top: 21px;bottom: 14px;">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="passwordshow text-danger" id="password"></div>
                </div>
              </div>
              <div class="form-login authentication-check">
                <div class="row">
                  <div class="col-12 d-flex align-items-center justify-content-between">
                    <div class="custom-control custom-checkbox">
                      <!-- <label class="checkboxs ps-4 mb-0 pb-0 line-height-1">
                        <input type="checkbox" class="form-control" />
                        <span class="checkmarks"></span>Remember me
                      </label> -->
                    </div>
                    <!-- <div class="text-end">
                      <router-link class="forgot-link" to="/forgot-password"
                        >Forgot Password?</router-link
                      >
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="form-login">
                <button type="submit" class="btn btn-login" :disabled="isLoading">
                  {{ isLoading ? 'Signing in...' : 'Sign In' }}
                </button>
              </div>
              <!-- <div class="signinform">
                <h4>
                  New on our platform?<router-link to="/register" class="hover-a">
                    Create an account</router-link
                  >
                </h4>
              </div> -->
              <!-- <div class="form-setlogin or-text">
                <h4>OR</h4>
              </div>
              <div class="form-sociallink">
                <ul class="d-flex">
                  <li>
                    <a href="javascript:void(0);" class="facebook-logo">
                      <img src="@/assets/img/icons/facebook-logo.svg" alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="@/assets/img/icons/google.png" alt="Google" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="apple-logo">
                      <img src="@/assets/img/icons/apple-logo.svg" alt="Apple" />
                    </a>
                  </li>
                </ul>
                <div
                  class="my-4 d-flex justify-content-center align-items-center copyright-text"
                >
                  <p>
                    Copyright &copy; {{ new Date().getFullYear() }} DreamsPOS. All rights
                    reserved
                  </p>
                </div>
              </div> -->
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";``
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Swal from 'sweetalert2';

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      usernameError: "",
      passwordError: "",
      isLoading: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async onSubmit(values) {
      document.getElementById("username").innerHTML = "";
      document.getElementById("password").innerHTML = "";
    
      this.isLoading = true;
      
      try {
        const response = await this.loginApi(values.username, values.password);
        
        if (response.data.success) {
          // localStorage.setItem('token', response.data.token); 
          this.$store.commit('setToken', response.data.token);
    
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Login successful!",
            showConfirmButton: false,
            timer: 1000,
            toast: true
          });
          setTimeout(() => {
            this.$router.push("/dashboard");
          }, 1000);
          
        } else {
          document.getElementById("username").innerHTML = response.data.message;
        }
      } catch (error) {
        document.getElementById("username").innerHTML = "An error occurred. Please try again.";
      } finally {
        this.isLoading = false;
      }
    },
    async loginApi(username, password) {
      return await this.$axios.post('login', { username, password });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      username: Yup.string().required("username is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
    return {
      schema,
      checked: ref(false),
    };
  },
};
</script>
