//Language Allemand


export default {
    Draft_Created_successfully: 'Entwurf erfolgreich erstellt',
    Draft_Sale_Deleted: 'Entwurfsverkauf erfolgreich gelöscht',
    Inventory_Valuation_Based_on_Average_Cost: 'Inventarbewertung basierend auf Durchschnittskosten',
    Payroll: 'Lohnabrechnung',
    Transfers_Money: 'Geldüberweisungen',
    Inventory_Valuation: 'Inventarbewertung',
    Deposits_Report: 'Einzahlungsbericht',
    Expense_Report: 'Ausgabenbericht',
    Deposit_Category: 'Einzahlungskategorie',
    Total_Deposits: 'Gesamteinzahlungen',
    Expense_Category: 'Ausgabenkategorie',
    Total_Expenses: 'Gesamtausgaben',
    ASSET_VALUE: 'Anlagewert',
    STOCK_ON_HAND: 'Lagerbestand',
    Variant_NAME: 'Variantenname',
    SKU: 'SKU',
    ITEM_NAME: 'Artikelname',
    From_Account: 'Vom Konto',
    To_Account: 'Zum Konto',
    Accounts_cannot_be_the_same: 'Konten dürfen nicht identisch sein',
    CountStock: 'Bestand zählen',
    Successfully_Generated_Count: 'Zählung erfolgreich erstellt',
    Count: 'Anzahl',
    file: 'Datei',
    Accounting: 'Buchhaltung',
    deposit_delete: 'Einzahlung löschen',
    deposit_edit: 'Einzahlung bearbeiten',
    deposit_add: 'Einzahlung hinzufügen',
    deposit_view: 'Einzahlung anzeigen',
    expense_add: 'Ausgabe hinzufügen',
    expense_edit: 'Ausgabe bearbeiten',
    expense_delete: 'Ausgabe löschen',
    List_Deposit: 'Einzahlungsliste',
    Create_deposit: 'Einzahlung erstellen',
    deposit_Category: 'Einzahlungskategorie',
    List_accounts: 'Kontoliste',
    Account: 'Konto',
    Choose_Account: 'Konto auswählen',
    Deposits: 'Einzahlungen',
    account_num: 'Kontonummer',
    Enter_account_num: 'Kontonummer eingeben',
    account_name: 'Kontoname',
    Enter_account_name: 'Kontoname eingeben',
    initial_balance: 'Anfangsbestand',
    Enter_initial_balance: 'Anfangsbestand eingeben',
    notes: 'Notizen',
    balance: 'Guthaben',
    Receipt: 'Beleg',
    Pos_Settings: 'POS-Einstellungen',
    Note_to_customer: 'Hinweis an den Kunden',
    Show_Note_to_customer: 'Kundennotiz anzeigen',
    Show_barcode: 'Barcode anzeigen',
    Show_Tax_and_Discount: 'Steuer & Rabatt anzeigen',
    Show_Customer: 'Kunde anzeigen',
    Show_Email: 'E-Mail anzeigen',
    Show_Phone: 'Telefon anzeigen',
    Show_Address: 'Adresse anzeigen',
    DefaultLanguage: 'Standardsprache',
    footer: 'Fußzeile',
    Received_Amount: 'Erhaltener Betrag',
    Paying_Amount: 'Zahlungsbetrag',
    Change: 'Wechselgeld',
    Paying_amount_is_greater_than_Received_amount: 'Der Zahlungsbetrag ist höher als der erhaltene Betrag',
    Paying_amount_is_greater_than_Grand_Total: 'Der Zahlungsbetrag ist höher als die Gesamtsumme',
    code_must_be_not_exist_already: 'Code darf nicht bereits existieren',
    You_will_find_your_backup_on: 'Sie finden Ihr Backup unter',
    and_save_it_to_your_pc: 'und speichern Sie es auf Ihrem PC',
    Scan_your_barcode_and_select_the_correct_symbology_below: 'Scannen Sie Ihren Barcode und wählen Sie unten die richtige Symbologie aus',
    Scan_Search_Product_by_Code_Name: 'Produkt nach Code oder Name scannen/suchen',
    Paper_size: 'Papiergröße',
    Clear_Cache: 'Cache leeren',
    Cache_cleared_successfully: 'Cache erfolgreich geleert',
    Failed_to_clear_cache: 'Cache konnte nicht geleert werden',
    Scan_Barcode: 'Barcode scannen',
    Please_use_short_name_of_unit: 'Bitte verwenden Sie die Kurzbezeichnung der Einheit',
    DefaultCustomer: 'Standardkunde',
    DefaultWarehouse: 'Standardlager',
    Payment_Gateway: 'Zahlungsgateway',
    SMS_Configuration: 'SMS-Konfiguration',
    Gateway: 'Gateway',
    Choose_Gateway: 'Gateway auswählen',
    Send_SMS: 'SMS senden',
    sms_config_invalid: 'Ungültige SMS-Konfiguration',
    Remove_Stripe_Key_Secret: 'Stripe-Schlüssel entfernen',
    credit_card_account_not_available: 'Kreditkartenkonto nicht verfügbar',
    Credit_Card_Info: 'Kreditkarteninformationen',
    developed_by: 'Entwickelt von',
    Unit_already_linked_with_sub_unit: 'Einheit bereits mit Untereinheit verknüpft',
    Total_Items_Quantity: 'Gesamtartikelanzahl',
    Value_by_Cost_and_Price: 'Wert nach Kosten und Preis',
    Search_this_table: 'Diese Tabelle durchsuchen',
    import_products: 'Produkte importieren',
    Field_optional: 'Feld optional',
    Download_exemple: 'Beispiel herunterladen',
    field_must_be_in_csv_format: 'Feld muss im CSV-Format sein',
    Successfully_Imported: 'Erfolgreich importiert',
    file_size_must_be_less_than_1_mega: 'Dateigröße muss weniger als 1 MB betragen',
    Please_follow_the_import_instructions: 'Bitte folgen Sie den Importanweisungen',
    must_be_exist: 'Muss existieren',
    Import_Customers: 'Kunden importieren',
    Import_Suppliers: 'Lieferanten importieren',
    Recent_Sales: 'Kürzliche Verkäufe',
    Create_Transfer: 'Transfer erstellen',
    order_products: 'Bestellprodukte',
    Search_Product_by_Code_Name: 'Produkt nach Code oder Name suchen',
    Reports_payments_Purchase_Return: 'Berichte Zahlungen Einkaufsrückgabe',
    Reports_payments_Sale_Return: 'Berichte Zahlungen Verkaufsrückgabe',
    payments_Sales_Return: 'Zahlungen Verkaufsrückgabe',
    payments_Purchases_Return: 'Zahlungen Einkaufsrückgabe',
    CreateSaleReturn: 'Verkaufsrückgabe erstellen',
    EditSaleReturn: 'Verkaufsrückgabe bearbeiten',
    SalesReturn: 'Verkaufsrückgabe',
    CreatePurchaseReturn: 'Einkaufsrückgabe erstellen',
    EditPurchaseReturn: 'Einkaufsrückgabe bearbeiten',
    PurchasesReturn: 'Einkaufsrückgabe',
    Due: 'Fällig',
    Profit: 'Gewinn',
    Revenue: 'Einnahmen',
    Sales_today: 'Heutiger Umsatz',
    People: 'Personen',
    Successfully_Created: 'Erfolgreich erstellt',
    Successfully_Updated: 'Erfolgreich aktualisiert',
    Success: 'Erfolg',
    Failed: 'Fehlgeschlagen',
    Warning: 'Warnung',
    Please_fill_the_form_correctly: 'Bitte füllen Sie das Formular korrekt aus',
    Field_is_required: 'Feld ist erforderlich',
    Error: 'Fehler!',
    you_are_not_authorized: 'Es tut uns leid! Sie sind nicht berechtigt.',
    Go_back_to_home: 'Zur Startseite zurückkehren',
    page_not_exist: 'Es tut uns leid! Die gesuchte Seite existiert nicht.',
    Choose_Status: 'Status auswählen',
    Choose_Method: 'Methode auswählen',
    Choose_Symbology: 'Symbologie auswählen',
    Choose_Category: 'Kategorie auswählen',
    Choose_Customer: 'Kunden auswählen',
    Choose_Supplier: 'Lieferant auswählen',
    Choose_Unit_Purchase: 'Kaufeinheit auswählen',
    Choose_Sub_Category: 'Unterkategorie auswählen',
    Choose_Brand: 'Marke auswählen',
    Choose_Warehouse: 'Lager auswählen',
    Choose_Unit_Sale: 'Verkaufseinheit auswählen',
    Enter_Product_Cost: 'Produktkosten eingeben',
    Enter_Stock_alert: 'Lagerwarnung eingeben',
    Choose_Unit_Product: 'Produkteinheit auswählen',
    Enter_Product_Price: 'Produktpreis eingeben',
    Enter_Name_Product: 'Produktnamen eingeben',
    Enter_Role_Name: 'Rollenname eingeben',
    Enter_Role_Description: 'Rollenbeschreibung eingeben',
    Enter_name_category: 'Kategoriename eingeben',
    Enter_Code_category: 'Kategoriecode eingeben',
    Enter_Name_Brand: 'Markennamen eingeben',
    Enter_Description_Brand: 'Markenbeschreibung eingeben',
    Enter_Code_Currency: 'Währungscode eingeben',
    Enter_name_Currency: 'Währungsnamen eingeben',
    Enter_Symbol_Currency: 'Währungssymbol eingeben',
    Enter_Name_Unit: 'Einheitennamen eingeben',
    Enter_ShortName_Unit: 'Kurzname der Einheit eingeben',
    Choose_Base_Unit: 'Basiseinheit auswählen',
    Choose_Operator: 'Operator auswählen',
    Enter_Operation_Value: 'Wert eingeben',
    Enter_Name_Warehouse: 'Lagername eingeben',
    Enter_Phone_Warehouse: 'Lagertelefon eingeben',
    Enter_Country_Warehouse: 'Land des Lagers eingeben',
    Enter_City_Warehouse: 'Stadt des Lagers eingeben',
    Enter_Email_Warehouse: 'Lager-E-Mail eingeben',
    Enter_ZipCode_Warehouse: 'Postleitzahl des Lagers eingeben',
        Choose_Currency: 'Währung auswählen',
    Thank_you_for_your_business: 'Vielen Dank für Ihr Geschäft!',
    Cancel: 'Abbrechen',
    New_Customer: 'Neukunde',
    Incorrect_Login: 'Falsche Anmeldedaten',
    Successfully_Logged_In: 'Erfolgreich eingeloggt',
    This_user_not_active: 'Dieser Benutzer ist nicht aktiv',
    SignIn: 'Anmelden',
    Create_an_account: 'Konto erstellen',
    Forgot_Password: 'Passwort vergessen?',
    Email_Address: 'E-Mail-Adresse',
    SignUp: 'Registrieren',
    Already_have_an_account: 'Haben Sie bereits ein Konto?',
    Reset_Password: 'Passwort zurücksetzen',
    Failed_to_authenticate_on_SMTP_server: 'Fehler bei der Authentifizierung auf dem SMTP-Server',
    We_cant_find_a_user_with_that_email_addres: 'Kein Benutzer mit dieser E-Mail-Adresse gefunden',
    We_have_emailed_your_password_reset_link: 'Wir haben Ihnen den Link zum Zurücksetzen des Passworts per E-Mail gesendet',
    Please_fill_the_Email_Adress: 'Bitte geben Sie die E-Mail-Adresse ein',
    Confirm_password: 'Passwort bestätigen',
    Your_Password_has_been_changed: 'Ihr Passwort wurde geändert',
    The_password_confirmation_does_not_match: 'Die Passwortbestätigung stimmt nicht überein',
    This_password_reset_token_is_invalid: 'Dieses Passwort-Reset-Token ist ungültig',
    Warehouse_report: 'Lagerbericht',
    All_Warehouses: 'Alle Lagerhäuser',
    Expense_List: 'Ausgabenliste',
    Expenses: 'Ausgaben',
    This_Week_Sales_Purchases: 'Diese Woche: Verkäufe & Käufe',
    Top_Selling_Products: 'Meistverkaufte Produkte',
    View_all: 'Alle anzeigen',
    Payment_Sent_Received: 'Zahlung gesendet und empfangen',
    Filter: 'Filter',
    Invoice_POS: 'POS-Rechnung',
    Invoice: 'Rechnung',
    Customer_Info: 'Kundeninformation',
    Company_Info: 'Firmeninformation',
    Invoice_Info: 'Rechnungsinformation',
    Order_Summary: 'Bestellübersicht',
    Quote_Info: 'Angebotsinformation',
    Del: 'Löschen',
    SuppliersPaiementsReport: 'Lieferantenzahlungsbericht',
    Purchase_Info: 'Kaufinformation',
    Supplier_Info: 'Lieferanteninformation',
    Return_Info: 'Rückgabeinformation',
    Expense_Category: 'Ausgabenkategorie',
    Create_Expense: 'Ausgabe erstellen',
    Details: 'Details',
    Discount_Method: 'Rabattmethode',
    Net_Unit_Cost: 'Netto-Stückkosten',
    Net_Unit_Price: 'Netto-Stückpreis',
    Edit_Expense: 'Ausgabe bearbeiten',
    All_Brand: 'Alle Marken',
    All_Category: 'Alle Kategorien',
    ListExpenses: 'Ausgaben auflisten',
    Create_Permission: 'Berechtigung erstellen',
    Edit_Permission: 'Berechtigung bearbeiten',
    Reports_payments_Sales: 'Berichte über Verkaufszahlungen',
    Reports_payments_Purchases: 'Berichte über Einkaufzahlungen',
    Reports_payments_Return_Customers: 'Berichte Rückzahlungen Kunden',
    Reports_payments_Return_Suppliers: 'Berichte Rückzahlungen Lieferanten',
    Expense_Deleted: 'Diese Ausgabe wurde gelöscht',
    Expense_Updated: 'Diese Ausgabe wurde aktualisiert',
    Expense_Created: 'Diese Ausgabe wurde erstellt',
    DemoVersion: 'Dies ist in der Demoversion nicht möglich',
    OrderStatistics: 'Bestellstatistiken',
    AlreadyAdd: 'Dieses Produkt wurde bereits hinzugefügt',
    AddProductToList: 'Produkt zur Liste hinzufügen',
    AddQuantity: 'Menge hinzufügen',
    InvalidData: 'Ungültige Daten',
    LowStock: 'Menge überschreitet den verfügbaren Lagerbestand',
    WarehouseIdentical: 'Beide Lager können nicht identisch sein',
    VariantDuplicate: 'Diese Variante ist bereits vorhanden',
    Filesize: 'Dateigröße',
    GenerateBackup: 'Backup erstellen',
    BackupDatabase: 'Datenbank-Backup',
    Backup: 'Sicherung',
    Paid: 'Bezahlt',
    Unpaid: 'Unbezahlt',
    IncomeExpenses: 'Einnahmen & Ausgaben',
    dailySalesPurchases: 'Tägliche Verkäufe und Einkäufe',
    ProductsExpired: 'Abgelaufene Produkte',
    Today: 'Heute',
    Income: 'Einnahmen',
    Sale: 'Verkauf',
    Actif: 'Aktiv',
    Inactif: 'Inaktiv',
    Customers: 'Kunden',
    Phone: 'Telefon',
    SearchByPhone: 'Nach Telefon suchen',
    Suppliers: 'Lieferanten',
    Quotations: 'Angebote',
    Sales: 'Verkäufe',
    Purchases: 'Einkäufe',
    Returns: 'Rückgaben',
    Settings: 'Einstellungen',
    SystemSettings: 'Systemeinstellungen',
    Users: 'Benutzer',
    GroupPermissions: 'Gruppenberechtigungen',
    Currencies: 'Währungen',
    Warehouses: 'Lagerhäuser',
    Units: 'Einheiten',
    UnitsPrchases: 'Einkaufseinheiten',
    UnitsSales: 'Verkaufseinheiten',
    Reports: 'Berichte',
    PaymentsReport: 'Zahlungsbericht',
    PaymentsPurchases: 'Einkaufszahlungen',
    PaymentsSales: 'Verkaufszahlungen',
    PaymentsReturns: 'Rückzahlungen',
    ReturnsInvoices: 'Rückgaberechnungen',
    ReturnsInvoicesData: 'Rückgaberechnungsdaten',
    ShowAll: 'Alle anzeigen',
    ProfitandLoss: 'Gewinn und Verlust',
    WarehouseStockChart: 'Lagerbestandsdiagramm',
    SalesReport: 'Verkaufsbericht',
    PurchasesReport: 'Einkaufsbericht',
    CustomersReport: 'Kundenbericht',
    SuppliersReport: 'Lieferantenbericht',
    SupplierReport: 'Lieferantenbericht',
    DailySalesData: 'Tägliche Verkaufsdaten',
    DailyPurchasesData: 'Tägliche Einkaufsdaten',
    Dernièrescinqrecords: 'Letzte fünf Einträge',
    Filters: 'Filter',
    date: 'Datum',
    ManagementCurrencies: 'Währungsmanagement',
    CurrencyCode: 'Währungscode',
    CurrencyName: 'Währungsname',
    Symbol: 'Symbol',
    Reference: 'Referenz',
    Supplier: 'Lieferant',
    PaymentStatus: 'Zahlungsstatus',
    Customer: 'Kunde',
    CustomerCode: 'Kundennummer',
    Status: 'Status',
    SupplierCode: 'Lieferantencode',
    Categorie: 'Kategorie',
    Categories: 'Kategorien',
    StockTransfers: 'Lagertransfers',
    StockManagement: 'Lagerverwaltung',
    dashboard: 'Dashboard',
    Products: 'Produkte',
    productsList: 'Produktliste',
    ProductManagement: 'Produktverwaltung',
    ProductQuantityAlerts: 'Mengenwarnungen',
    CodeProduct: 'Produktcode',
    ProductTax: 'Produktsteuer',
    SubCategorie: 'Unterkategorie',
    Name_product: 'Produktname',
    StockAlert: 'Lagerwarnung',
    warehouse: 'Lagerhaus',
    Tax: 'Steuer',
    BuyingPrice: 'Kaufpreis',
    SellPrice: 'Verkaufspreis',
    Quantity: 'Menge',
    UnitSale: 'Verkaufseinheit',
    UnitPurchase: 'Kaufeinheit',
    All: 'Alle',
    EditProduct: 'Produkt bearbeiten',
    AddProduct: 'Produkt hinzufügen',
    SearchByCode: 'Nach Code suchen',
    SearchByName: 'Nach Name suchen',
    ProductDetails: 'Produktdetails',
    CustomerName: 'Kundenname',
    CustomerManagement: 'Kundenverwaltung',
    Add: 'Hinzufügen',
    add: 'hinzufügen',
    Edit: 'Bearbeiten',
    Close: 'Schließen',
    PleaseSelect: 'Bitte auswählen',
    Action: 'Aktion',
    Email: 'E-Mail',
    EditCustomer: 'Kunde bearbeiten',
    AddCustomer: 'Kunde hinzufügen',
    Country: 'Land',
    City: 'Stadt',
    Adress: 'Adresse',
    CustomerDetails: 'Kundendetails',
    CustomersList: 'Kundenliste',
    SupplierCode: 'Lieferantencode',
    SupplierName: 'Lieferantenname',
    SuppliersManagement: 'Lieferantenverwaltung',
    SupplierDetails: 'Lieferantendetails',
    QuotationsManagement: 'Angebotsverwaltung',
    SubTotal: 'Zwischensumme',
    MontantReste: 'Restbetrag',
    complete: 'Abgeschlossen',
    EnAttendant: 'Wartend',
    Recu: 'Empfangen',
    partial: 'Teilweise',
    Retournee: 'Zurückgekehrt',
    DetailQuote: 'Angebotsdetails',
    EditQuote: 'Angebot bearbeiten',
    CreateSale: 'Verkauf erstellen',
    DownloadPdf: 'PDF herunterladen',
    QuoteEmail: 'Angebot per E-Mail senden',
    DeleteQuote: 'Angebot löschen',
    AddQuote: 'Angebot hinzufügen',
    SelectProduct: 'Produkt auswählen',
    ProductCodeName: 'Produkt (Code - Name)',
    Price: 'Preis',
    CurrentStock: 'Aktueller Lagerbestand',
    Total: 'Gesamt',
    Num: 'Nr.',
        Unitcost: 'Kosten pro Einheit',
    to: 'zu',
    Subject: 'Betreff',
    Message: 'Nachricht',
    EmailCustomer: 'E-Mail an den Kunden',
    Sent: 'Gesendet',
    Quote: 'Angebot',
    Hello: 'Hallo',
    AttachmentQuote: 'Im Anhang finden Sie Ihr Angebot',
    AddProducts: 'Produkte zur Bestellliste hinzufügen',
    SelectWarehouse: 'Bitte Lager auswählen',
    SelectCustomer: 'Bitte Kunde auswählen',
    SalesManagement: 'Verkaufsverwaltung',
    Balance: 'Guthaben',
    QtyBack: 'Rückgabemenge',
    TotalReturn: 'Gesamtrückgabe',
    Amount: 'Betrag',
    SaleDetail: 'Verkaufsdetails',
    EditSale: 'Verkauf bearbeiten',
    AddSale: 'Verkauf hinzufügen',
    ShowPayment: 'Zahlungen anzeigen',
    AddPayment: 'Zahlung hinzufügen',
    EditPayment: 'Zahlung bearbeiten',
    EmailSale: 'Verkauf per E-Mail senden',
    DeleteSale: 'Verkauf löschen',
    ModePaiement: 'Zahlungsmethode',
    Paymentchoice: 'Zahlungsauswahl',
    Note: 'Notiz',
    PaymentComplete: 'Zahlung abgeschlossen!',
    PurchasesManagement: 'Einkaufsverwaltung',
    Ordered: 'Bestellt',
    DeletePurchase: 'Einkauf löschen',
    EmailPurchase: 'Einkauf per E-Mail senden',
    EditPurchase: 'Einkauf bearbeiten',
    PurchaseDetail: 'Einkaufsdetails',
    AddPurchase: 'Einkauf hinzufügen',
    EmailSupplier: 'Lieferant per E-Mail kontaktieren',
    PurchaseInvoice: 'Einkaufsrechnungen',
    PurchasesInvoicesData: 'Einkaufsrechnungsdaten',
    SalesInvoice: 'Verkaufsrechnungen',
    SalesInvoicesData: 'Verkaufsrechnungsdaten',
    UserManagement: 'Benutzerverwaltung',
    Firstname: 'Vorname',
    lastname: 'Nachname',
    username: 'Benutzername',
    password: 'Passwort',
    Newpassword: 'Neues Passwort',
    ChangeAvatar: 'Avatar ändern',
    LeaveBlank: 'Feld leer lassen, wenn keine Änderung',
    type: 'Typ',
    UserPermissions: 'Benutzerberechtigungen',
    RoleName: 'Rollenname',
    RoleDescription: 'Rollenbeschreibung',
    AddPermissions: 'Berechtigungen hinzufügen',
    View: 'Ansehen',
    Del: 'Löschen',
    NewAdjustement: 'Neue Anpassung',
    EditAdjustement: 'Anpassung bearbeiten',
    CannotSubstraction: 'Produkte mit Lagerbestand 0 können nicht subtrahiert werden',
    Addition: 'Hinzufügen',
    Subtraction: 'Subtraktion',
    profil: 'Profil',
    logout: 'Abmelden',
    PurchaseAlreadyPaid: 'Änderungen nicht möglich, Einkauf bereits bezahlt',
    SaleAlreadyPaid: 'Änderungen nicht möglich, Verkauf bereits bezahlt',
    ReturnAlreadyPaid: 'Änderungen nicht möglich, Rückgabe bereits bezahlt',
    QuoteAlready: 'Dieses Angebot hat bereits einen Verkauf generiert',
    QuotationComplete: 'Dieses Angebot ist abgeschlossen',
    SiteConfiguration: 'Seitenkonfiguration',
    Language: 'Sprache',
    DefaultCurrency: 'Standardwährung',
    LoginCaptcha: 'Login-Captcha',
    DefaultEmail: 'Standard-E-Mail',
    SiteName: 'Seitenname',
    ChangeLogo: 'Logo ändern',
    SMTPConfiguration: 'SMTP-Konfiguration',
    HOST: 'Host',
    PORT: 'Port',
    encryption: 'Verschlüsselung',
    SMTPIncorrect: 'SMTP-Konfiguration ist falsch',
    Discount: 'Rabatt',
    OrderTax: 'Bestellsteuer',
    Shipping: 'Versand',
    CompanyName: 'Firmenname',
    CompanyPhone: 'Firmentelefon',
    CompanyAdress: 'Firmenadresse',
    Code: 'Code',
    image: 'Bild',
    Printbarcode: 'Barcode drucken',
    ReturnsCustomers: 'Rücksendungen Kunden',
    ReturnsSuppliers: 'Rücksendungen Lieferanten',
    FactureReturnCustomers: 'Rückgaberechnungen Kunden',
    FactureReturnSuppliers: 'Rückgaberechnungen Lieferanten',
    NodataAvailable: 'Keine Daten verfügbar',
    ProductImage: 'Produktbild',
    Barcode: 'Barcode',
    pointofsales: 'Verkaufsstelle',
    CustomUpload: 'Benutzerdefinierter Upload',
    pointofsaleManagement: 'POS-Verwaltung',
    Adjustment: 'Anpassung',
    Updat: 'Aktualisieren',
    Reset: 'Zurücksetzen',
    print: 'Drucken',
    SearchByEmail: 'Nach E-Mail suchen',
    ChooseProduct: 'Produkt wählen',
    Qty: 'Menge',
    Items: 'Artikel',
    AmountHT: 'Betrag ohne MwSt',
    AmountTTC: 'Betrag inkl. MwSt',
    PleaseSelectSupplier: 'Bitte Lieferant auswählen',
    PleaseSelectStatut: 'Bitte Status auswählen',
    PayeBy: 'Bezahlt mit',
    ChooseWarehouse: 'Lager auswählen',
    payNow: 'Jetzt bezahlen',
    ListofCategory: 'Kategorieliste',
    Description: 'Beschreibung',
    submit: 'Einreichen',
    ProblemCreatingThisInvoice: 'Problem beim Erstellen der Rechnung. Bitte erneut versuchen.',
    ProblemPayment: 'Problem bei der Zahlung. Bitte erneut versuchen.',
    CreateAdjustment: 'Anpassung erstellen',
    Afewwords: 'Ein paar Worte über...',
    UserImage: 'Benutzerbild',
    UpdateProduct: 'Produkt aktualisieren',
    Brand: 'Marke',
    BarcodeSymbology: 'Barcode-Symbologie',
    ProductCost: 'Produktkosten',
    ProductPrice: 'Produktpreis',
    UnitProduct: 'Produkteinheit',
    TaxMethod: 'Steuermethode',
    MultipleImage: 'Mehrere Bilder',
    ProductHasMultiVariants: 'Produkt hat mehrere Varianten',
    ProductHasPromotion: 'Produkt hat Promotion',
    PromotionStart: 'Promotionsbeginn',
    PromotionEnd: 'Promotionsende',
    PromotionPrice: 'Aktionspreis',
    Price: 'Preis',
    Cost: 'Kosten',
    Unit: 'Einheit',
    ProductVariant: 'Produktvariante',
    Variant: 'Variante',
    UnitPrice: 'Stückpreis',
    CreateReturnCustomer: 'Kundenrückgabe erstellen',
    EditReturnCustomer: 'Kundenrückgabe bearbeiten',
    CreateReturnSupplier: 'Lieferantenrückgabe erstellen',
    Documentation: 'Dokumentation',
    EditReturnSupplier: 'Lieferantenrückgabe bearbeiten',
    FromWarehouse: 'Vom Lager',
    ToWarehouse: 'Zum Lager',
    EditTransfer: 'Transfer bearbeiten',
    TransferDetail: 'Transferdetails',
    Pending: 'Ausstehend',
    Received: 'Empfangen',
    Ordered: 'Bestellt',
    PermissionsManager: 'Berechtigungsmanager',
    BrandManager: 'Markenmanager',
    BrandImage: 'Markenbild',
    BrandName: 'Markenname',
    BrandDescription: 'Markenbeschreibung',
    BaseUnit: 'Basiseinheit',
    ManagerUnits: 'Einheitenverwaltung',
    OperationValue: 'Betriebswert',
    Operator: 'Operator',
    Top5Products: 'Top 5 Produkte',
    Last5Sales: 'Letzte 5 Verkäufe',
    ListAdjustments: 'Anpassungen auflisten',
    ListTransfers: 'Transfers auflisten',
    CreateTransfer: 'Transfer erstellen',
    OrdersManager: 'Bestellverwaltung',
    ListQuotations: 'Angebote auflisten',
    ListPurchases: 'Einkäufe auflisten',
    ListSales: 'Verkäufe auflisten',
    ListReturns: 'Rückgaben auflisten',
    PeopleManager: 'Personenverwaltung',
    ListofBrand: 'Markenliste',



    // Sweet Alert
    Delete: {
        Title: 'Sind Sie sicher?',
        Text: 'Das kann nicht rückgängig gemacht werden!',
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        Deleted: 'Gelöscht!',
        Failed: 'Fehlgeschlagen!',
        Therewassomethingwronge: 'Etwas ist schief gelaufen',
        CustomerDeleted: 'Dieser Kunde wurde gelöscht',
        SupplierDeleted: 'Dieser Lieferant wurde gelöscht',
        QuoteDeleted: 'Dieses Angebot wurde gelöscht',
        SaleDeleted: 'Dieser Verkauf wurde gelöscht',
        PaymentDeleted: 'Diese Zahlung wurde gelöscht',
        PurchaseDeleted: 'Dieser Einkauf wurde gelöscht',
        ReturnDeleted: 'Diese Rückgabe wurde gelöscht',
        ProductDeleted: 'Dieses Produkt wurde gelöscht',
        ClientError: 'Dieser Kunde ist bereits mit einer anderen Operation verknüpft',
        ProviderError: 'Dieser Lieferant ist bereits mit anderen Operationen verbunden',
        UserDeleted: 'Dieser Benutzer wurde gelöscht',
        UnitDeleted: 'Diese Einheit wurde gelöscht',
        RoleDeleted: 'Diese Rolle wurde gelöscht',
        TaxeDeleted: 'Diese Steuer wurde gelöscht',
        SubCatDeleted: 'Diese Unterkategorie wurde gelöscht',
        CatDeleted: 'Diese Kategorie wurde gelöscht',
        WarehouseDeleted: 'Dieses Lager wurde gelöscht',
        AlreadyLinked: 'Dieses Produkt ist bereits mit anderen Prozessen verknüpft',
        AdjustDeleted: 'Diese Anpassung wurde gelöscht',
        TitleCurrency: 'Diese Währung wurde erfolgreich gelöscht',
        TitleTransfer: 'Transfer wurde erfolgreich gelöscht',
        BackupDeleted: 'Backup wurde erfolgreich gelöscht',
        TitleBrand: 'Diese Marke wurde gelöscht'
    },
    Update: {
        TitleBrand: 'Diese Marke wurde aktualisiert',
        TitleProfile: 'Ihr Profil wurde erfolgreich aktualisiert',
        TitleAdjust: 'Anpassung erfolgreich aktualisiert',
        TitleRole: 'Rolle erfolgreich aktualisiert',
        TitleUnit: 'Einheit erfolgreich aktualisiert',
        TitleUser: 'Benutzer erfolgreich aktualisiert',
        TitleCustomer: 'Kunde erfolgreich aktualisiert',
        TitleQuote: 'Angebot erfolgreich aktualisiert',
        TitleSale: 'Verkauf erfolgreich aktualisiert',
        TitlePayment: 'Zahlung erfolgreich aktualisiert',
        TitlePurchase: 'Einkauf erfolgreich aktualisiert',
        TitleReturn: 'Rückgabe erfolgreich aktualisiert',
        TitleProduct: 'Produkt erfolgreich aktualisiert',
        TitleSupplier: 'Lieferant erfolgreich aktualisiert',
        TitleTaxe: 'Steuer erfolgreich aktualisiert',
        TitleCat: 'Kategorie erfolgreich aktualisiert',
        TitleWarehouse: 'Lager erfolgreich aktualisiert',
        TitleSetting: 'Einstellungen erfolgreich aktualisiert',
        TitleCurrency: 'Währung erfolgreich aktualisiert',
        TitleTransfer: 'Transfer erfolgreich aktualisiert'
    },
    Create: {
        TitleBrand: 'Diese Marke wurde erstellt',
        TitleTransfer: 'Transfer erfolgreich erstellt',
        TitleRole: 'Rolle erfolgreich erstellt',
        TitleUnit: 'Einheit erfolgreich erstellt',
        TitleUser: 'Benutzer erfolgreich erstellt',
        TitleCustomer: 'Kunde erfolgreich erstellt',
        TitleQuote: 'Angebot erfolgreich erstellt',
        TitleSale: 'Verkauf erfolgreich erstellt',
        TitlePayment: 'Zahlung erfolgreich erstellt',
        TitlePurchase: 'Einkauf erfolgreich erstellt',
        TitleReturn: 'Rückgabe erfolgreich erstellt',
        TitleProduct: 'Produkt erfolgreich erstellt',
        TitleSupplier: 'Lieferant erfolgreich erstellt',
        TitleTaxe: 'Steuer erfolgreich erstellt',
        TitleCat: 'Kategorie erfolgreich erstellt',
        TitleWarehouse: 'Lager erfolgreich erstellt',
        TitleAdjust: 'Anpassung erfolgreich erstellt',
        TitleCurrency: 'Währung erfolgreich erstellt'
    },
    Send: {
        TitleEmail: 'E-Mail erfolgreich gesendet'
    },
    return: {
        TitleSale: 'Dieser Verkauf ist bereits mit einer Rückgabe verknüpft!'
    },
    ReturnManagement: 'Rückgabeverwaltung',
    ReturnDetail: 'Rückgabedetails',
    EditReturn: 'Rückgabe bearbeiten',
    AddReturn: 'Rückgabe hinzufügen',
    EmailReturn: 'Rückgabe per E-Mail senden',
    DeleteReturn: 'Rückgabe löschen',
    Retoursurcharge: 'Rücknahmegebühr',
    Laivrison: 'Lieferung',
    SelectSale: 'Verkauf auswählen',
    ZeroPardefault: 'Artikel löschen oder Rückgabebetrag auf Null setzen, wenn keine Rückgabe',
    Return: 'Rückkehr',
    Purchase: 'Einkauf',
    TotalSales: 'Gesamtumsatz',
    TotalPurchases: 'Gesamteinkäufe',
    TotalReturns: 'Gesamtrückgaben',
    PaiementsNet: 'Nettozahlungen',
    PaiementsSent: 'Zahlungen gesendet',
    PaiementsReceived: 'Zahlungen empfangen',
    Recieved: 'Empfangen',
    Sent: 'Gesendet',
    ProductQuantityAlerts: 'Mengenwarnungen',
    ProductCode: 'Produktcode',
    ProductName: 'Produktname',
    AlertQuantity: 'Warnmenge',
    WarehouseStockChart: 'Lagerbestandsdiagramm',
    TotalProducts: 'Gesamtprodukte',
    TotalQuantity: 'Gesamtmenge',
    TopCustomers: 'Top-Kunden',
    TotalAmount: 'Gesamtbetrag',
    TotalPaid: 'Gesamtbezahlt',
    CustomerSalesReport: 'Kundenverkaufsbericht',
    CustomerPaiementsReport: 'Kundenzahlungsbericht',
    CustomerQuotationsReport: 'Kundenangebotbericht',
    Payments: 'Zahlungen',
    TopSuppliers: 'Top-Lieferanten',
    SupplierPurchasesReport: 'Lieferanteneinkaufsbericht',
    SupplierPaiementsReport: 'Lieferantenzahlungsbericht',
    Name: 'Name',
    Code: 'Code',
    ManagementWarehouse: 'Lagerverwaltung',
    ZipCode: 'Postleitzahl',
    managementCategories: 'Kategorienverwaltung',
    Codecategorie: 'Kategoriekode',
    Namecategorie: 'Kategoriename',
    Parentcategorie: 'Überkategorie',
    managementTax: 'Steuerverwaltung',
    TaxName: 'Steuername',
    TaxRate: 'Steuersatz',
    managementUnitPurchases: 'Einkaufseinheitenverwaltung',
    managementUnitSales: 'Verkaufseinheitenverwaltung',
    ShortName: 'Kurzname',
    PleaseSelectThesebeforeaddinganyproduct: 'Bitte wählen Sie diese aus, bevor Sie ein Produkt hinzufügen',
    StockAdjustement: 'Bestandsanpassung',
    PleaseSelectWarehouse: 'Bitte wählen Sie das Lagerhaus aus, bevor Sie ein Produkt auswählen',
    StockTransfer: 'Lagertransfer',
    SelectPeriod: 'Zeitraum auswählen',
    ThisYear: 'Dieses Jahr',
    ThisToday: 'Heute',
    ThisMonth: 'Diesen Monat',
    ThisWeek: 'Diese Woche',
    AdjustmentDetail: 'Anpassungsdetails',
    ActivateUser: 'Benutzer wurde aktiviert',
    DisActivateUser: 'Benutzer wurde deaktiviert',
    NotFound: 'Seite nicht gefunden.',
    oops: 'Fehler! Seite nicht gefunden.',
    couldNotFind: 'Die gesuchte Seite wurde nicht gefunden. Sie können jedoch',
    ReturnDashboard: 'zum Dashboard zurückkehren',

    //hrm module
    hrm: 'HRM',
    Employees: 'Mitarbeiter',
    Attendance: 'Anwesenheit',
    Leave_request: 'Urlaubsantrag',
    Leave_type: 'Urlaubsart',
    Company: 'Unternehmen',
    Departments: 'Abteilungen',
    Designations: 'Bezeichnungen',
    Office_Shift: 'Büroschicht',
    Holidays: 'Feiertage',
    Enter_Company_Name: 'Unternehmensname eingeben',
    Enter_email_address: 'E-Mail-Adresse eingeben',
    Enter_Company_Phone: 'Unternehmens-Telefonnummer eingeben',
    Enter_Company_Country: 'Unternehmensland eingeben',
    Created_in_successfully: 'Erfolgreich erstellt',
    Updated_in_successfully: 'Erfolgreich aktualisiert',
    Deleted_in_successfully: 'Erfolgreich gelöscht',
    department: 'Abteilung',
    Enter_Department_Name: 'Abteilungsname eingeben',
    Choose_Company: 'Unternehmen auswählen',
    Department_Head: 'Abteilungsleiter',
    Choose_Department_Head: 'Abteilungsleiter auswählen',
    Enter_Shift_name: 'Schichtname eingeben',
    Monday_In: 'Montag rein',
    Monday_Out: 'Montag raus',
    Tuesday_In: 'Dienstag rein',
    tuesday_out: 'Dienstag raus',
    wednesday_in: 'Mittwoch rein',
    wednesday_out: 'Mittwoch raus',
    thursday_in: 'Donnerstag rein',
    thursday_out: 'Donnerstag raus',
    friday_in: 'Freitag rein',
    friday_out: 'Freitag raus',
    saturday_in: 'Samstag rein',
    saturday_out: 'Samstag raus',
    sunday_in: 'Sonntag rein',
    sunday_out: 'Sonntag raus',
    Holiday: 'Feiertag',
    Enter_title: 'Titel eingeben',
    title: 'Titel',
    start_date: 'Startdatum',
    Enter_Start_date: 'Startdatum eingeben',
    Finish_Date: 'Enddatum',
    Enter_Finish_date: 'Enddatum eingeben',
    Please_provide_any_details: 'Bitte geben Sie Details an',
    Attendances: 'Anwesenheiten',
    Enter_Attendance_date: 'Anwesenheitsdatum eingeben',
    Time_In: 'Zeit rein',
    Time_Out: 'Zeit raus',
    Choose_Employee: 'Mitarbeiter auswählen',
    Employee: 'Mitarbeiter',
    Work_Duration: 'Arbeitsdauer',
    remaining_leaves_are_insufficient: 'Verbleibende Urlaubstage sind unzureichend',
    Leave_Type: 'Urlaubsart',
    Days: 'Tage',
    Department: 'Abteilung',
    Choose_leave_type: 'Urlaubsart wählen',
    Choose_status: 'Status wählen',
    Leave_Reason: 'Urlaubsgrund',
    Enter_Reason_Leave: 'Urlaubsgrund eingeben',
    Add_Employee: 'Mitarbeiter hinzufügen',
    FirstName: 'Vorname',
    Enter_FirstName: 'Vorname eingeben',
    LastName: 'Nachname',
    Enter_LastName: 'Nachname eingeben',
    Gender: 'Geschlecht',
    Choose_Gender: 'Geschlecht wählen',
    Enter_Birth_date: 'Geburtsdatum eingeben',
    Birth_date: 'Geburtsdatum',
    Enter_Country: 'Land eingeben',
    Enter_Phone_Number: 'Telefonnummer eingeben',
    joining_date: 'Eintrittsdatum',
    Enter_joining_date: 'Eintrittsdatum eingeben',
    Choose_Designation: 'Bezeichnung wählen',
    Designation: 'Bezeichnung',
    Office_Shift: 'Büroschicht',
    Choose_Office_Shift: 'Büroschicht wählen',
    Enter_Leaving_Date: 'Austrittsdatum eingeben',
    Leaving_Date: 'Austrittsdatum',
    Annual_Leave: 'Jahresurlaub',
    Enter_Annual_Leave: 'Jahresurlaub eingeben',
    Remaining_leave: 'Resturlaub',
    Employee_Details: 'Mitarbeiterdetails',
    Basic_Information: 'Grundlegende Informationen',
    Family_status: 'Familienstand',
    Choose_Family_status: 'Familienstand wählen',
    Employment_type: 'Beschäftigungsart',
    Select_Employment_type: 'Beschäftigungsart wählen',
    Enter_City: 'Stadt eingeben',
    Province: 'Provinz',
    Enter_Province: 'Provinz eingeben',
    Enter_Address: 'Adresse eingeben',
    Enter_Zip_code: 'Postleitzahl eingeben',
    Zip_code: 'Postleitzahl',
    Hourly_rate: 'Stundenlohn',
    Enter_Hourly_rate: 'Stundenlohn eingeben',
    Basic_salary: 'Grundgehalt',
    Enter_Basic_salary: 'Grundgehalt eingeben',
    Social_Media: 'Soziale Medien',
    Skype: 'Skype',
    Enter_Skype: 'Skype eingeben',
    Facebook: 'Facebook',
    Enter_Facebook: 'Facebook eingeben',
    WhatsApp: 'WhatsApp',
    Enter_WhatsApp: 'WhatsApp eingeben',
    LinkedIn: 'LinkedIn',
    Enter_LinkedIn: 'LinkedIn eingeben',
    Twitter: 'Twitter',
    Enter_Twitter: 'Twitter eingeben',
    Experiences: 'Erfahrungen',
    bank_account: 'Bankkonto',
    Company_Name: 'Firmenname',
    Location: 'Standort',
    Enter_location: 'Standort eingeben',
    Enter_Description: 'Beschreibung eingeben',
    Bank_Name: 'Bankname',
    Enter_Bank_Name: 'Bankname eingeben',
    Bank_Branch: 'Bankfiliale',
    Enter_Bank_Branch: 'Bankfiliale eingeben',
    Bank_Number: 'Banknummer',
    Enter_Bank_Number: 'Banknummer eingeben',
    Assigned_warehouses: 'Zugewiesene Lager',
    Top_customers: 'Top-Kunden',
    Attachment: 'Anhang',
    view_employee: 'Mitarbeiter anzeigen',
    edit_employee: 'Mitarbeiter bearbeiten',
    delete_employee: 'Mitarbeiter löschen',

    Created_by: 'Erstellt von',
    Add_product_IMEI_Serial_number: 'Produkt-IMEI/Seriennummer hinzufügen',
    Product_Has_Imei_Serial_number: 'Produkt hat IMEI/Seriennummer',
    IMEI_SN: 'IMEI/SN',
    Shipments: 'Sendungen',
    delivered_to: 'Geliefert an',
    shipment_ref: 'Sendungsnummer',
    sale_ref: 'Verkaufsnummer',
    Edit_Shipping: 'Versand bearbeiten',
    Packed: 'Verpackt',
    Shipped: 'Versendet',
    Delivered: 'Geliefert',
    Cancelled: 'Storniert',
    Shipping_status: 'Versandstatus',
    Users_Report: 'Benutzerbericht',
    stock_report: 'Bestandsbericht',
    TotalPurchases: 'Gesamteinkäufe',
    Total_quotations: 'Gesamtangebote',
    Total_return_sales: 'Gesamtverkaufsrückgaben',
    Total_return_purchases: 'Gesamteinkaufsrückgaben',
    Total_transfers: 'Gesamttransfers',
    Total_adjustments: 'Gesamtanpassungen',
    User_report: 'Benutzerbericht',
    Current_stock: 'Aktueller Lagerbestand',
    product_name: 'Produktname',
    Total_Customers_Due: 'Gesamtschuld der Kunden',
    Total_Suppliers_Due: 'Gesamtschuld der Lieferanten',
    Some_warehouses: 'Einige Lager',
    All_Warehouses: 'Alle Lager',
    Product_Cost: 'Produktkosten',

    sms_settings: 'SMS-Einstellungen',
    pos_settings: 'POS-Einstellungen',
    payment_gateway: 'Zahlungsgateways',
    mail_settings: 'E-Mail-Einstellungen',
    Nexmo_SMS: 'Nexmo SMS',
    TWILIO_SMS: 'TWILIO SMS',
    Default_SMS_Gateway: 'Standard-SMS-Gateway',
    module_settings: 'Moduleinstellungen',
    Module_enabled_success: 'Modul erfolgreich aktiviert',
    Module_Disabled_success: 'Modul erfolgreich deaktiviert',
    update_settings: 'Einstellungen aktualisieren',
    Please_Upload_the_Correct_Module: 'Bitte laden Sie das richtige Modul hoch',
    Uploaded_Success: 'Erfolgreich hochgeladen',
    Customer_details: 'Kundendetails',
    Edit_Customer: 'Kunde bearbeiten',
    Delete_Customer: 'Kunde löschen',
    Pay_Due: 'Fällige Zahlung leisten',
    Paying_amount_is_greater_than_Total_Due: 'Der Zahlungsbetrag ist größer als der fällige Gesamtbetrag',
    Customer_Invoice: 'Kundenrechnung',
    This_Product_Not_For_Selling: 'Dieses Produkt ist nicht zum Verkauf bestimmt',
    Welcome_to_your_Dashboard: 'Willkommen im Dashboard',
    Total_Payable: 'Gesamtbetrag zu zahlen',
    Choose_Sale_Ref: 'Verkaufsreferenz auswählen',
    Please_Select_Sale: 'Bitte Verkauf auswählen',
    Sale_Ref: 'Verkaufsreferenz',
    list_product_returns: 'Produktretouren auflisten',
    Qty_return: 'Rückgabemenge',
    Choose_Purchase_Ref: 'Kaufreferenz auswählen',
    Purchase_Ref: 'Kaufreferenz',
    please_select_purchase: 'Bitte Einkauf auswählen',
    qty_return_is_greater_than_qty_purchased: 'Rückgabemenge ist größer als die gekaufte Menge',
    qty_return_is_greater_than_qty_sold: 'Rückgabemenge ist größer als die verkaufte Menge',
    Quantity_sold: 'Verkaufte Menge',
    qty_purchased: 'Gekaufte Menge',
    Sender_Name: 'Absendername',
    The_module_must_be_uploaded_as_zip_file: 'Das Modul muss als Zip-Datei hochgeladen werden',
    Upload_Module: 'Modul hochladen',
    All_Modules_Installed: 'Alle Module installiert',
    Current_Version: 'Aktuelle Version',
    You_already_have_the_latest_version: 'Sie haben bereits die neueste Version',
    Update_Available: 'Update verfügbar',
    Update_Log: 'Aktualisierungsprotokoll',
    Update_Now: 'Jetzt aktualisieren',
    View_Change_Log: 'Änderungsprotokoll anzeigen',
    invoice_footer: 'Rechnungsfußzeile',
    Please_Wait_for_the_update: 'HINWEIS: Bitte warten Sie, bis das Update vollständig abgeschlossen ist. Es wird nicht empfohlen, den Browser zu schließen oder den Prozess zu stoppen',
    Note_update: 'Hinweis: Stellen Sie sicher, dass Sie Ihre aktuelle Version und Datenbank sichern, bevor Sie das Update durchführen',
    Time_Zone: 'Zeitzone',
    Payment_note: 'Zahlungshinweis',
    sale_note: 'Verkaufsnotizen',

    Total_Sale_Due: 'Gesamtschuld aus Verkäufen',
    Total_Sell_Return_Due: 'Gesamtrückgabeschuld aus Verkäufen',
    pay_all_sell_due_at_a_time: 'Alle Verkaufsschulden auf einmal zahlen',
    pay_all_sell_return_due_at_a_time: 'Alle Rückgabeschulden auf einmal zahlen',
    Gross_Profit: 'Bruttogewinn',
    pay_all_purchase_due_at_a_time: 'Alle Einkaufsschulden auf einmal zahlen',
    Delete_Provider: 'Lieferant löschen',
    Edit_Provider: 'Lieferant bearbeiten',
    Provider_details: 'Lieferantendetails',
    Total_Purchase_Due: 'Gesamtschuld aus Einkäufen',
    Provider_Credit_Note: 'Gutschrift für Lieferanten',
    Customer_Credit_Note: 'Gutschrift für Kunden',
    Sell_Return: 'Verkaufsrückgabe',
    Purchase_Return: 'Einkaufsrückgabe',
    Tax_Number: 'Steuernummer',
    Please_add_return_quantity: 'Bitte Rückgabemenge hinzufügen',
    Return_exist_for_the_Transaction: 'Rückgabe existiert für diese Transaktion',
    qty_return_is_greater_than_Quantity_Remaining: 'Rückgabemenge ist größer als die verbleibende Menge',
    products_refunded_alert: 'Alle Produkte mit einer auf Null gesetzten Menge werden nicht zurückerstattet',
    pay_all_purchase_return_due_at_a_time: 'Alle Rückgabeschulden auf einmal zahlen',
    Total_Purchase_Return_Due: 'Gesamtrückgabeschuld aus Einkäufen',
    Purchase_return_due: 'Rückgabeschuld aus Einkäufen',
    Sell_return_due: 'Rückgabeschuld aus Verkäufen',
    product_report: 'Produktbericht',
    product_sales_report: 'Produktverkaufsbericht',
    Product_purchases_report: 'Produkteinkaufsbericht',
    Qty_sold: 'Verkaufte Menge',
    Qty_purchased: 'Gekaufte Menge',
    Filter_by_warehouse: 'Nach Lager filtern',
    Enable_Print_Invoice: 'Rechnung automatisch drucken',
    Show_Warehouse: 'Lager anzeigen',

    credit_card_info: 'Kreditkarteninformationen',
    Saved_Credit_Card_Info: 'Gespeicherte Kreditkarteninformationen',
    Credit_card_changed_successfully: 'Kreditkarte erfolgreich geändert',
    notification_template: 'Benachrichtigungsvorlage',
    email_notification: 'E-Mail-Benachrichtigung',
    sms_notification: 'SMS-Benachrichtigung',
    Change_product_details: 'Produktdetails ändern',
    edit_tax_and_discount_and_shipping: 'Steuern, Rabatte und Versand bearbeiten',
    Create_Quotation_with_Stock: 'Angebot mit Lagerbestand erstellen',
    Variant_Name: 'Variantenname',
    Variant_cost: 'Variantenkosten',
    Variant_price: 'Variantenpreis',
    Variant_code: 'Variantencode',
    Please_wait_until_the_product_is_loaded: 'Bitte warten, bis das Produkt geladen ist',
    Choose_SMS_Gateway: 'SMS-Gateway auswählen',
    Notification_Client: 'Benachrichtigung an den Kunden',
    Available_Tags: 'Verfügbare Tags',
    Email_Subject: 'E-Mail-Betreff',
    Email_body: 'E-Mail-Inhalt',
    Notification_Supplier: 'Benachrichtigung an den Lieferanten',
    sms_body: 'SMS-Inhalt',
    sms_templates: 'SMS-Vorlagen',
    email_templates: 'E-Mail-Vorlagen',

    Color: 'Farbe',
    Status_Name: 'Statusname',
    Status_Code: 'Statuscode',
    Status_Color: 'Statusfarbe',
    Enter_Name_Status: 'Statusnamen eingeben',
    Enter_Code_Status: 'Statuscode eingeben',
    Enter_Color_Status: 'Statusfarbe eingeben',
    Repair_Status: 'Reparaturstatus',

    // Additional for Repair Management
    Repair_Managements: 'Reparaturverwaltung',
    Repair_Categories: 'Reparaturkategorien',
    Repair_Category: 'Reparaturkategorie',
    CategoryName: 'Kategoriename',
    Icon: 'Symbol',
    Enter_Name_RepairCategory: 'Reparaturkategoriename eingeben',
    Enter_Code_RepairCategory: 'Reparaturkategoriecode eingeben',
    Enter_Icon_RepairCategory: 'Reparatursymbol eingeben',
    Brand_Model: 'Marke/Modell',
    Brand_Models: 'Marken/Modelle',
    BrandModelName: 'Marke/Modell Name',
    Enter_Name_BrandModel: 'Name der Marke/Modell eingeben',
    BrandModelDescription: 'Marke/Modell Beschreibung',
    Enter_Description_BrandModel: 'Beschreibung der Marke/Modell eingeben',
    BrandModelImage: 'Marke/Modell Bild',
    Brand_Model_Devices: 'Geräte der Marke/Modell',
    Brand_Model_Device: 'Gerät der Marke/Modell',
    Choose_BrandModel: 'Marke/Modell auswählen',
    BrandModelDeviceName: 'Gerätename',
    Enter_Name_BrandModelDevice: 'Gerätename eingeben',
    BrandModelDeviceDescription: 'Gerätebeschreibung',
    Enter_Description_BrandModelDevice: 'Gerätebeschreibung eingeben',
    BrandModelDeviceImage: 'Gerätebild',
    Devices_Maintenances: 'Gerätewartung',
    BrandModelDeviceMaintenances: 'Gerätewartungen',
    BrandModelDeviceMaintenance: 'Gerätewartung',
    Enter_maintenance_BrandModelDevice: 'Gerätewartung eingeben',
    Choose_Brand_Model_Device: 'Gerät auswählen',
    BrandModelDeviceItemCost: 'Kosten',
    Enter_cost_BrandModelDevice: 'Kosten eingeben',
    RepairTax: 'Reparatursteuer',
    UsedProducts: 'Gebrauchte Produkte',
    Add_Repair_Request: 'Reparaturanfrage hinzufügen',
    Edit_Repair_Request: 'Reparaturanfrage bearbeiten',
    Repair_Request_List: 'Liste der Reparaturanfragen',
    Please_Select_Device: 'Bitte Gerät auswählen',
    ClientNote: 'Kundennotiz',
    MaintenanceNote: 'Wartungsnotiz',
    RepairRequests: 'Reparaturanfragen',

    Create_2: {
        TitleRepairStatus: 'Reparaturstatus erfolgreich erstellt',
        TitleRepairCategory: 'Reparaturkategorie erfolgreich erstellt',
        TitleBrandModel: 'Marke/Modell erfolgreich erstellt',
        TitleBrandModelDevice: 'Gerät erfolgreich erstellt',
        TitleBrandModelDeviceItem: 'Gerätewartung erfolgreich erstellt',
        TitleRepairRequest: 'Reparaturanfrage erfolgreich erstellt'
    },
    Update_2: {
        TitleRepairStatus: 'Reparaturstatus erfolgreich aktualisiert',
        TitleRepairCategory: 'Reparaturkategorie erfolgreich aktualisiert',
        TitleBrandModel: 'Marke/Modell erfolgreich aktualisiert',
        TitleBrandModelDevice: 'Gerät erfolgreich aktualisiert',
        TitleBrandModelDeviceItem: 'Gerätewartung erfolgreich aktualisiert',
        TitleRepairRequest: 'Reparaturanfrage erfolgreich aktualisiert'
    },
    Delete_2: {
        TitleRepairStatus: 'Dieser Status wurde gelöscht',
        TitleRepairCategory: 'Diese Kategorie wurde gelöscht',
        TitleBrandModel: 'Diese Marke/Modell wurde gelöscht',
        TitleBrandModelDevice: 'Dieses Gerät wurde gelöscht',
        TitleBrandModelDeviceItem: 'Diese Gerätewartung wurde gelöscht',
        TitleRepairRequest: 'Diese Reparaturanfrage wurde gelöscht'
    },
    Shipment_Request: 'Versandanfrage',
    
    // Status updates for Repair Requests
    Edit_Repair_Request_Status: "Reparaturanfragestatus bearbeiten",
    Repair_Request_Status: "Status der Reparaturanfrage",
    Repair_Request_Payment: "Zahlungen für Reparaturanfragen erhalten",
    SMS: "SMS",
    EMAIL: "E-Mail",
    Maintenance: "Wartung",
    SelectRepairMaintenance: "Gerätewartung auswählen",
    direct_repair: "Direkte Reparatur",
    edit_maintenance_repair: "Wartung bearbeiten (Direktreparatur)",
    there_is_not_repairs_request: "Keine Reparaturanfragen vorhanden",
    repair_dashboard: "Dashboard (Reparaturen)",
    today: "Heute",
    yesterday: "Gestern",
    last_seven_days: "Letzte sieben Tage",
    current_month: "Aktueller Monat",
    last_month: "Letzter Monat",
    current_year: "Aktuelles Jahr",
    last_year: "Letztes Jahr",
    
    Rate: "Rate",
    warranty: "Garantie",
    enter_imei_number: "IMEI-Nummer eingeben",
    enter_warranty_in_month: "Garantie in Monaten eingeben",
    months: "Monate",
    View_Completed: "Abgeschlossene anzeigen",
    mr: "Herr",
    mrs: "Frau",
    ms: "Fräulein",
    person: "Person",
    company: "Unternehmen",
    personOrCompany: "Person/Unternehmen",
    Street: "Straße",
    PostCode: "Postleitzahl",
    Filter_by_Date_Period: "Nach Zeitraum filtern",
    repair_appointments: "Reparaturtermine",
    time: "Zeit",
    New: "Neu",
    APPROVE: "Genehmigen",
    APPROVED: "Genehmigt",
    Repair_Appointment_has_been_approved: "Dieser Reparaturtermin wurde genehmigt",
    Approve_Repair_Appointment: "Reparaturtermin genehmigen",
    CANCEL: "Stornieren",
    CANCELED: "Storniert",
    Repair_Appointment_has_been_canceled: "Dieser Reparaturtermin wurde storniert",
    Cancel_Repair_Appointment: "Reparaturtermin stornieren",
    DONE: "Erledigt",
    LONGITUDE: "Längengrad",
    LATITUDE: "Breitengrad",
    TEILE: "Teile",
    ACCESSOIRES: "Zubehör",
    DIVERS: "Diverses",
    ORIGINAL: "Original",
    PREMIUM: "Premium",
    SUPER_COPY: "Superkopie",
    PRODUCT_TYPE: "Produkttyp",
    Choose_Product_Type: "Produkttyp wählen",
    QUALITY: "Qualität",
    Choose_Quality: "Qualität wählen",
    Enter_Color: "Farbe eingeben",
    Order: "Bestellung",
    Enter_Order: "Bestellung eingeben",
    ActivateCategory: "Diese Kategorie ist aktiviert",
    DisActivateCategory: "Diese Kategorie ist deaktiviert",
    Please_wait_until_the_maintenance_is_loaded: "Bitte warten, bis die Wartung geladen ist",
    customs_cost: "Zollkosten",
    shipping_cost: "Versandkosten",
    taxes_cost: "Steuerkosten",
    customs_fee: "Zollgebühr",
    shipping_fee: "Versandgebühr",
    taxes_fee: "Steuergebühr",
    supplier_code: "Lieferantencode",
    Cost_Before: "Kosten vorab",
    Select_customer: "Kunde auswählen",
//----------------

    Note_to_company:'Hinweis an Unternehmen',
    insurance_company:'Versicherungsgesellschaft',
    company_name:'Firmenname',
    inspectable_parts: 'Prüfbare Teile',
    device_status: 'Gerätestatus',
    device_status_descriptions: 'Gerätestatus-Beschreibungen',

    Enter_Name_Insurance_company: 'Name der Versicherungsgesellschaft eingeben',
    TitleInsuranceCompany: 'Versicherungsgesellschaft',
    Delete_Insurance_companyDeleted: 'Diese Versicherungsgesellschaft wurde gelöscht.',
    Create_TitleInsuranceCompany: 'Diese Versicherungsgesellschaft wurde erstellt',
    Update_TitleInsuranceCompany: 'Diese Versicherungsgesellschaft wurde aktualisiert',

    Enter_Name_InspectablePart: 'Name des prüfbaren Teils eingeben',
    Delete_TitleInspectablePart: 'Dieses prüfbare Teil wurde gelöscht.',
    Update_TitleInspectablePart: 'Dieses prüfbare Teil wurde aktualisiert',
    Create_TitleInspectablePart: 'Dieses prüfbare Teil wurde erstellt',

    Enter_status_deviceStatusDescription: 'Gerätestatus-Beschreibung eingeben',
    Delete_TitleDeviceStatusDescription: 'Diese Gerätestatus-Beschreibung wurde gelöscht.',
    Update_TitleDeviceStatusDescription: 'Diese Gerätestatus-Beschreibung wurde aktualisiert',
    Create_TitleDeviceStatusDescription: 'Diese Gerätestatus-Beschreibung wurde erstellt',
    RepairStatistics: 'Reparaturstatistik',
    insurance_number: 'Versicherungsnummer',
    enter_insurance_number: 'Versicherungsnummer eingeben',
    insurance_damage_number: 'Versicherungsschadennummer',
    enter_insurance_damage_number: 'Versicherungsschadennummer eingeben',
    Choose_insurance_company: 'Versicherungsgesellschaft auswählen',
    ClientDescription: 'Kundenbeschreibung',
    UserDescription: 'Benutzerbeschreibung',
    enter_password: 'Passwort eingeben',
    Work: 'Funktioniert',
    NotWork: 'Funktioniert nicht',
    due_date: 'Fälligkeitsdatum',
    Frame_condition: 'Rahmen-Zustand',
    Scratch_free: 'Kratzerfrei',
    Scratched: 'Zerkratzt',
    right: 'Rechts',
    left: 'Links',
    top: 'Oben',
    bottom: 'Unten',
    Add_Invoice: 'Rechnung hinzufügen',
    RepairRequestDetail: 'Reparaturanfrage-Details',
    RepairRequest: 'Reparaturanfrage',
    Device_info: 'Geräte-Info',
    NoClientDescription: 'Keine Kundenbeschreibung',
    NoUserDescription: 'Keine Benutzerbeschreibung',
    RepairStatus: 'Reparaturstatus',
    RepairStatusLog: 'Reparaturstatus-Protokoll',
    Invoice_Receive: 'Rechnung erhalten',
    estimated_cost: 'Geschätzte Kosten',
    Choose_Date_Period: 'Datumsbereich auswählen',
    update_products_price: 'Preis aktualisieren',
    import_products_price: "Produktpreise importieren",
    net: 'Netto',
    //----------------

};
