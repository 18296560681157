// src/plugins/axios.js
import axios from "axios";
import { i18n } from '@/main.js';

const AxiosPlugin = {
  install(app) {
    // Set the base URL for Axios
    // axios.defaults.baseURL = 'https://my.drmobile.ch/api/';
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL+'api/';
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    
    // Set Accept and Content-Type headers
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // Set Access-Control-Allow-Origin header
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // Adjust the value as needed

    // Add a request interceptor to include the current language in the query parameters
    axios.interceptors.request.use(config => {
      const currentLanguage = i18n.global.locale || 'en';
      
      // Retrieve the token from local storage or your auth store
      const token = localStorage.getItem('token') || ''; // Replace with your actual auth store logic
      config.headers['Authorization'] = `Bearer ${token}`;

      if (['post', 'put', 'delete'].includes(config.method)) {
        // Add the current language to POST, PUT, DELETE requests
        config.data = {
          ...config.data,
          lang: currentLanguage
        };
      } else if (config.method === 'get') {
        // Add the current language to GET requests
        config.params = {
          ...config.params,
          local: currentLanguage
        };
      }
      
      return config;
    });

    // Add a response interceptor to handle errors
    axios.interceptors.response.use(
      response => response,
      async (error) => {
        if (error.response && error.response.status === 419) {
          // Retry the original request
          return axios(error.config);
        }
        return Promise.reject(error);
      }
    );

    // Make Axios available in all components
    app.config.globalProperties.$axios = axios;
  },
};

export default AxiosPlugin;