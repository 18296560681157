import axios from 'axios'


const state = {
    isAuthenticated: localStorage.getItem('token') ? true : false,
    Permissions: null,
    user: {},
    loading: false,
    error: null,
    notifs:0,
    token:  localStorage.getItem('token') || '',
    warehouses: [],
    selectedWarehouseId: localStorage.getItem('selected_warehouse_id')||null,
};


const getters = {
    isAuthenticated: state => state.isAuthenticated ,
    currentUser: state => state.user,
    currentUserPermissions: state => state.Permissions,
    loading: state => state.loading,
    notifs_alert: state => state.notifs,
    error: state => state.error,
    token: state => state.token,
    shouldRefreshUser: state => !state.Permissions || !state.user || Object.keys(state.user).length === 0,
    warehouses: state => state.warehouses,
    selectedWarehouseId: state => state.selectedWarehouseId,
};

const mutations = {
    setLoading(state, data) {
        state.loading = data;
        state.error = null;
    },
    setError(state, data) {
        state.error = data;
        state.loggedInUser = null;
        state.loading = false;
    },
    clearError(state) {
        state.error = null;
    },

    setPermissions(state, Permissions) {
        state.Permissions = Permissions;
    },

    


    setUser(state, user) {
        state.user = user;
    },



    Notifs_alert(state, notifs) {
        state.notifs = notifs;
    },

    setWarehouses(state, warehouses) {
        if (warehouses.length === 1) {
            state.selectedWarehouseId = warehouses[0]['id'];
        } else if (warehouses.length > 1) {
            warehouses.unshift({ id: '', name: 'All Warehouses' });
        }
        state.warehouses = warehouses;
    },

    async logout({ commit }) {
        try {
            // Clear the store after successful logout
            state.user = null;
            state.Permissions = null;
            state.loggedInUser = null;
            state.loading = false;
            state.error = null;
            state.isAuthenticated = false;
            state.token = null;
            state.warehouses = [];
            localStorage.removeItem('token');
            await axios.post("logout");
            
            
        } catch (error) {
            console.error("Logout API error:", error);
        }
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
        state.isAuthenticated = !!token;
    },
    setSelectedWarehouseId(state, id) {
        // Check if id is in warehouses, set selectedWarehouseId accordingly
        id = Number(id);
        const warehouseIds = state.warehouses.map(warehouse => warehouse.id);
        state.selectedWarehouseId = warehouseIds.includes(id) ? id : '';
        localStorage.setItem('selected_warehouse_id', state.selectedWarehouseId);  
        // this.$router.go(0);
    },
};

const actions = {

    async refreshUserPermissions(context) {
        
        await axios.get("get_user_auth").then((userAuth) => {
            let Permissions = userAuth.data.permissions
            let user = userAuth.data.user
            let notifs = userAuth.data.notifs
            let warehouses = userAuth.data.warehouses

            context.commit('setPermissions', Permissions)
            context.commit('setUser', user)
            context.commit('Notifs_alert', notifs)
            context.commit('setWarehouses', warehouses)
        }).catch(() => {
            context.commit('setPermissions', null)
            context.commit('setUser', null)
            context.commit('Notifs_alert', null)
            context.commit('setWarehouses', [])
        });
    },
    
};

export default {
    state,
    getters,
    actions,
    mutations
};
