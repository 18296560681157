//Language Français


export default {
    Draft_Created_successfully: 'Brouillon créé avec succès',
    Draft_Sale_Deleted: 'Vente du brouillon supprimée',
    Inventory_Valuation_Based_on_Average_Cost: 'Évaluation des stocks basée sur le coût moyen',
    Payroll: 'Paie',
    Transfers_Money: 'Transferts d\'argent',
    Inventory_Valuation: 'Évaluation des stocks',
    Deposits_Report: 'Rapport des dépôts',
    Expense_Report: 'Rapport des dépenses',
    Deposit_Category: 'Catégorie de dépôt',
    Total_Deposits: 'Total des dépôts',
    Expense_Category: 'Catégorie de dépenses',
    Total_Expenses: 'Total des dépenses',
    ASSET_VALUE: 'VALEUR DE L\'ACTIF',
    STOCK_ON_HAND: 'Stock en main',
    Variant_NAME: 'Nom de la variante',
    SKU: 'SKU',
    ITEM_NAME: 'NOM DE L\'ARTICLE',
    From_Account: 'Du compte',
    To_Account: 'Au compte',
    Accounts_cannot_be_the_same: 'Les comptes ne peuvent pas être les mêmes',
    CountStock: 'Compter Stock',
    Successfully_Generated_Count: 'Comptage Généré avec Succès',
    Count: 'Décompte',
    file: 'Fichier',
    Accounting: 'Comptabilité',
    deposit_delete: 'Supprimer le Dépôt',
    deposit_edit: 'Modifier le Dépôt',
    deposit_add: 'Ajouter un Dépôt',
    deposit_view: 'Voir le Dépôt',
    expense_add: 'Ajouter une Dépense',
    expense_edit: 'Modifier une Dépense',
    expense_delete: 'Supprimer une Dépense',
    List_Deposit: 'Liste des Dépôts',
    Create_deposit: 'Créer un Dépôt',
    deposit_Category: 'Catégorie de Dépôt',
    List_accounts: 'Liste des Comptes',
    Account: 'Compte',
    Choose_Account: 'Choisir un Compte',
    Deposits: 'Dépôts',
    account_num: 'Numéro de Compte',
    Enter_account_num: 'Entrer le Numéro de Compte',
    account_name: 'Nom du Compte',
    Enter_account_name: 'Entrer le Nom du Compte',
    initial_balance: 'Solde Initial',
    Enter_initial_balance: 'Entrer le Solde Initial',
    notes: 'Notes',
    balance: 'Solde',

    Receipt:'Reçu',
    Pos_Settings:'Paramètres du point de vente (Reçu)',
    Note_to_customer:'Remarque au client',
    Show_Note_to_customer:'Afficher la Remarque au client',
    Show_barcode:'Afficher le code barre',
    Show_Tax_and_Discount:'Afficher les taxes, les remises et les frais d\'expédition',
    Show_Customer:'Afficher le client',
    Show_Email:'Afficher l\'e-mail',
    Show_Phone:'Afficher le téléphone',
    Show_Address:'Afficher l\'adresse',

    DefaultLanguage:'Langage par défaut',
    footer:'bas de page',
    Received_Amount:'Montant reçu',
    Paying_Amount:'Montant à payer',
    Change:'changement',
    Paying_amount_is_greater_than_Received_amount:'Le montant à payer est supérieur au montant reçu',
    Paying_amount_is_greater_than_Grand_Total:'Le montant à payer est supérieur au montant total',
    code_must_be_not_exist_already:'le code ne doit pas déjà exister',
    You_will_find_your_backup_on:'Vous trouverez votre sauvegarde sur',
    and_save_it_to_your_pc:'et enregistrez-le sur votre pc',
    Scan_your_barcode_and_select_the_correct_symbology_below:'Scannez votre code-barres et sélectionnez la symbologie correcte ci-dessous',
    Scan_Search_Product_by_Code_Name:'Scannez/recherchez un produit par nom ou code',
    Paper_size:'Taille de papier',
    Clear_Cache:'Vider le cache',
    Cache_cleared_successfully:'Cache vidé avec succès',
    Failed_to_clear_cache:'Échec de vider du cache',
    Scan_Barcode:'Scanner de code-barres',
    Please_use_short_name_of_unit:'Veuillez utiliser le nom abrégé de l\'unité',
    DefaultCustomer:'Client par défaut',
    DefaultWarehouse:'Entrepôt par défaut',
    Payment_Gateway:'Passerelle de paiement',
    SMS_Configuration:'Configuration SMS',
    Gateway:'Passerelle de paiement',
    Choose_Gateway:'Choisissez la passerelle de paiement',
    Send_SMS :'message envoyé avec succès',
    sms_config_invalid:'configuration sms invalide',
    Remove_Stripe_Key_Secret:'Supprimer les clés d\'API Stripe',
    credit_card_account_not_available :'Compte de carte de crédit non disponible',
    Credit_Card_Info:'Informations sur la carte de crédit',
    developed_by:'Développé par',
    Unit_already_linked_with_sub_unit:'Unité déjà liée à la sous-unité',
    Total_Items_Quantity : 'Total des articles et quantité',
    Value_by_Cost_and_Price : 'Valeur par coût et prix',
    Search_this_table: 'Rechercher dans ce tableau',
    import_products:'Importer des produits',
    Field_optional:'Champ facultatif',
    Download_exemple:'Télécharger l\'exemple',
    field_must_be_in_csv_format:'Le champ doit être au format csv',
    Successfully_Imported:'Importé avec succès',
    file_size_must_be_less_than_1_mega:'La taille du fichier doit être inférieure à 1 méga',
    Please_follow_the_import_instructions:'Veuillez suivre les instructions d\'importation',
    must_be_exist:'l\'unité doit déjà être créée',
    Import_Customers:'Importer des clients',
    Import_Suppliers:'Importer des fournisseurs',
    Recent_Sales : 'Ventes récentes',
    Create_Transfer : 'Créer un transfert',
    order_products : 'Products commandés',
    Search_Product_by_Code_Name : 'Rechercher un produit par code ou par nom',
    Reports_payments_Purchase_Return:'Rapports sur les paiements de retour d\'achat',
    Reports_payments_Sale_Return:'Rapports sur les paiements de retour de vente',
    payments_Sales_Return:'Paiements retour de vente',
    payments_Purchases_Return:'Paiements retour d\'achats',
    CreateSaleReturn:'Créer un retour de vente',
    EditSaleReturn:'Modifier le retour de vente',
    SalesReturn:'Retour des ventes',
    CreatePurchaseReturn:'Créer un retour d\'achat',
    EditPurchaseReturn:'Modifier le retour d\'achat',
    PurchasesReturn:'Retour des achats',
    Due:'Dû',
    Profit: 'Profit',
    Revenue: 'Revenu',
    Sales_today: 'Ventes aujourd\'hui',
    People: 'Gens',
    Successfully_Created: 'Créé avec succès',
    Successfully_Updated: 'Mise à jour réussie',
    Success: 'Succès',
    Failed: 'Échec',
    Warning: 'Alert',
    Error: 'Erreur!',
    you_are_not_authorized: 'Pardon! vous n\'êtes pas autorisé.',
    Go_back_to_home: 'Retournez à la page d\'accueil',
    page_not_exist: 'Pardon! La page que vous recherchez n\'existe pas.',
    Please_fill_the_form_correctly: 'Veuillez remplir le formulaire correctement',
    Field_is_required: 'Champ requis',
    Choose_Status: 'Choisissez le statut',
    Choose_Method: 'Choisissez la méthode',
    Choose_Symbology: 'Choisissez la symbologie',
    Choose_Category: 'Choisissez la catégorie',
    Choose_Customer: 'Choisissez le client',
    Choose_Supplier: 'Choisissez un fournisseur',
    Choose_Unit_Purchase: 'Choisissez l\'unité d\'achat',
    Choose_Sub_Category: 'Choisissez la sous-catégorie',
    Choose_Brand: 'Choisissez la marque',
    Choose_Warehouse: 'Choisissez l\'entrepôt',
    Choose_Unit_Sale: 'Choisissez l\'unité de vente',
    Enter_Product_Cost: 'Entrez le prix d\'achat',
    Enter_Stock_alert: 'Entrer l\'alerte de stock',
    Choose_Unit_Product: 'Choisissez l\'unité de produit',
    Enter_Product_Price: 'Entrez le prix de vente',
    Enter_Name_Product: 'Entrez le nom du produit',
    Enter_Role_Name: 'Entrez le nom du rôle',
    Enter_Role_Description: 'Entrez la description du rôle',
    Enter_name_category: 'Entrez le nom de la catégorie',
    Enter_Code_category: 'Entrez le code de la catégorie',
    Enter_Name_Brand: 'Entrez le nom de la marque',
    Enter_Description_Brand: 'Entrez la description de la marque',
    Enter_Code_Currency: 'Entrez le code de devise',
    Enter_name_Currency: 'Entrez le nom Devise',
    Enter_Symbol_Currency: 'Entrez le symbole de la devise',
    Enter_Name_Unit: 'Entrez le nom de l`\'unité',
    Enter_ShortName_Unit: 'Entrez le nom abrégé de l\'unité',
    Choose_Base_Unit: 'Choisissez l\'unité de base',
    Choose_Operator: 'Choisissez un opérateur',
    Enter_Operation_Value: 'Entrez la valeur de l\'opération',
    Enter_Name_Warehouse: 'Entrez le nom de l\'entrepôt',
    Enter_Phone_Warehouse: 'Entrez le téléphone de l\'entrepôt',
    Enter_Country_Warehouse: 'Entrez le pays de l\'entrepôt',
    Enter_City_Warehouse: 'Entrez la ville de l\'entrepôt',
    Enter_Email_Warehouse: 'Entrez  e-mail de l\'entrepôt',
    Enter_ZipCode_Warehouse: 'Entrez le code postal de l\'entrepôt',
    Choose_Currency: 'Choisir la devise',
    Thank_you_for_your_business: 'Merci de votre confiance!',
    Cancel: 'Annuler',
    New_Customer: 'Nouveau client',
    Incorrect_Login: 'Login incorrect',
    Successfully_Logged_In: 'Connexion réussie',
    This_user_not_active: 'Cet utilisateur n\'est pas actif',
    SignIn: 'S\'identifier',
    Create_an_account: 'Créer un compte',
    Forgot_Password: 'Mot de passe oublié ?',
    Email_Address: 'Adresse e-mail',
    SignUp: 'S\'inscrire',
    Already_have_an_account: 'Vous avez déjà un compte ?',
    Reset_Password: 'réinitialiser le mot de passe',
    Failed_to_authenticate_on_SMTP_server: 'Échec de l\'authentification sur le serveur SMTP',
    We_cant_find_a_user_with_that_email_addres: 'Nous ne pouvons pas trouver un utilisateur avec cette adresse e-mail',
    We_have_emailed_your_password_reset_link: 'Nous avons envoyé votre lien de réinitialisation de mot de passe par e-mail',
    Please_fill_the_Email_Adress: 'Veuillez remplir l\'adresse e-mail',
    Confirm_password: 'Confirmez le mot de passe',
    Your_Password_has_been_changed: 'Votre mot de passe a été changé',
    The_password_confirmation_does_not_match: 'La confirmation du mot de passe ne correspond pas',
    This_password_reset_token_is_invalid: 'Ce jeton de réinitialisation de mot de passe n\'est pas valide',
    Warehouse_report: 'Rapport d\'entrepôt',
    All_Warehouses: 'Tous les entrepôts',
    Expense_List: 'Liste de dépenses',
    Expenses: 'dépenses',
    This_Week_Sales_Purchases: 'Ventes et achats de cette semaine',
    Top_Selling_Products: 'Produits les plus vendus',
    View_all: 'Voir tout',
    Payment_Sent_Received: 'Paiement envoyé et reçu',
    Filter: 'Filtre',
    Invoice_POS: 'Facture POS',
    Invoice: 'Facture',
    Customer_Info: 'Infos client',
    Company_Info: 'Infos société',
    Invoice_Info: 'Infos facture',
    Order_Summary: 'Résumé de la commande',
    Quote_Info: 'Devis Infos',
    Del: 'Effacer',
    SuppliersPaiementsReport: 'Fournisseurs Rapport Paiements',
    Purchase_Info: 'Infos d\'achat',
    Supplier_Info: 'Info fournisseur',
    Return_Info: 'Info de retour',
    Expense_Category: 'Catégorie de dépenses',
    Create_Expense: 'Créer une dépense',
    Details: 'Détails',
    Discount_Method: 'Méthode de remise',
    Net_Unit_Cost: 'Coût unitaire net',
    Net_Unit_Price: 'Prix unitaire net',
    Edit_Expense: 'Modifier les dépenses',
    All_Brand: 'Toute marque',
    All_Category: 'All Category',
    ListExpenses: 'Liste des dépenses',
    Create_Permission: 'Créer une autorisation',
    Edit_Permission: 'Modifier l\'autorisation',
    Reports_payments_Sales: 'Rapports des paiements des ventes',
    Reports_payments_Purchases: 'Rapports des achats de paiements',
    Reports_payments_Return_Customers: 'Rapports de paiements Clients de retour',
    Reports_payments_Return_Suppliers: 'Rapports des paiements Retour Fournisseurs',
    Expense_Deleted: 'Cette dépense a été supprimée',
    Expense_Updated: 'Cette dépense a été mise à jour',
    Expense_Created: 'Cette dépense a été créée',
    DemoVersion: 'Vous ne pouvez pas faire cela dans la version de démonstration',
    OrderStatistics: 'Statistiques de ventes',
    AlreadyAdd: 'Ce produit est déjà ajouté !!',
    AddProductToList: 'Veuillez ajouter le produit à la liste !!',
    AddQuantity: 'Veuillez ajouter la quantité de produit !!',
    InvalidData: 'Données invalides !!',
    LowStock: 'la quantité dépasse la quantité disponible en stock',
    WarehouseIdentical: 'Les deux magasins ne peuvent pas être identiques !!',
    VariantDuplicate: 'Cette variable est redondante !!',
    Filesize: 'Taille du fichier',
    GenerateBackup: 'Générer une sauvegarde',
    BackupDatabase: 'Sauvegarde de la base de données',
    Backup: 'Sauvegarde DB',
    Paid: 'Payé',
    Unpaid: 'Non payé',
    IncomeExpenses: 'Revenus et dépenses',
    dailySalesPurchases: 'Ventes et achats quotidiens',
    ProductsExpired: 'Produits expirés',
    Today: 'Aujourd\'hui',
    Income: 'Revenu',
    Expenses: 'Dépenses',
    Sale: 'Vente',
    Phone: 'Télé',
    SearchByPhone: 'Filter par Télé',
    Actif: 'Active',
    Inactif: 'Inactive',
    CustomerName: 'Nom Client',
    StockManagement: 'Gestion Du Stock',
    dashboard: 'Tableau de bord',
    Products: 'Produits',
    productsList: 'Liste de produits',
    StockTransfers: 'Stock Transfers',
    Customers: 'Clients',
    Suppliers: 'Fournisseurs',
    Quotations: 'Devis',
    Sales: 'Ventes',
    Purchases: 'Achats',
    Returns: 'Returns',
    Settings: 'Paramètres',
    SystemSettings: 'Paramètres du système',
    Users: 'Utilisateurs',
    GroupPermissions: 'Autorisations de groupe',
    Currencies: 'Devises',
    ProductTax: 'Produits Taxe',
    Categories: 'Categories',
    Warehouses: 'Entrepôt',
    Units: 'Unités',
    UnitsPrchases: 'Unités Achats',
    UnitsSales: 'Unités Ventes',
    Reports: 'Rapports',
    PaymentsReport: 'Paiements Rapports',
    PaymentsPurchases: 'Paiements Achats',
    PaymentsSales: 'Paiements Ventes',
    PaymentsReturns: 'Paiements Retours',
    ReturnsInvoices: 'Factures des retours',
    ReturnsInvoicesData: 'données des Factures de retours',
    ShowAll: 'Afficher tous les enregistrements de tous les utilisateurs',
    Discount: 'Remise',
    OrderTax: 'Taxe de commande',
    Shipping: 'livraison',
    ProfitandLoss: 'Profit et perte',
    ProductQuantityAlerts: 'Alertes de quantité',
    WarehouseStockChart: 'Warehouse Stock Chart',
    SalesReport: 'Ventes Rapports',
    PurchasesReport: 'Achats Rapports',
    CustomersReport: 'Clients Rapports',
    SuppliersReport: 'Fournisseurs Rapports',
    SupplierReport: 'Fournisseur Rapports',
    DailySalesData: 'Ventes quotidiens',
    DailyPurchasesData: 'Achats quotidiens',
    Dernièrescinqrecords: 'Dernières cinq records',
    Filters: 'Filtres',
    date: 'Date',
    Reference: 'Référence',
    Supplier: 'Fournisseur',
    PaymentStatus: 'Statut de Paiement',
    Customer: 'Client',
    CustomerCode: 'Client Code',
    Status: 'Statut',
    SupplierCode: 'Fournisseur Code',
    //produit
    ProductManagement: 'Gestion des Produits',
    CodeProduct: 'Code Produit',
    Categorie: 'Catégorie',
    SubCategorie: 'Sous-catégorie',
    Name_product: 'Désignation',
    StockAlert: 'Stock Alert',
    warehouse: 'Magasin',
    Tax: 'Taxe',
    BuyingPrice: 'Prix d\'achat',
    SellPrice: 'Prix de vente',
    Quantity: 'Quantité',
    Action: 'Action',
    UnitSale: 'Unités de Vente',
    UnitPurchase: 'Unités d\'achat',
    ProductDetails: 'Détails Produit',
    //filters
    All: 'Tous',
    EditProduct: 'Modifier Produit',
    AddProduct: 'Ajouter Produit',
    SearchByCode: 'Filter par Code',
    SearchByName: 'Filter par Nom',
    Add: 'Ajouter',
    add: 'Ajouter',
    Edit: 'Modifier',
    Close: 'Fermer',
    PleaseSelect: 'Veuillez sélectionner',
    CustomerManagement: 'Gestion Du Clients',
    Email: 'Email',
    EditCustomer: 'Modifier Client',
    AddCustomer: 'Ajouter Client',
    Country: 'Pays',
    City: 'Ville',
    Adress: 'Adresse',
    CustomerDetails: 'Détails Client',
    CustomersList: 'Liste Des Clients',
    SupplierCode: 'Code Fournisseur',
    SupplierName: 'Nom Fournisseur',
    SuppliersManagement: 'Gestion des Fournisseurs',
    SupplierDetails: 'Détails Fournisseur',
    QuotationsManagement: 'Gestion des Devis',
    SubTotal: 'Grand Total',
    complete: 'completé',
    EnAttendant: 'En attente',
    Recu: 'Recu',
    partial: 'partial',
    Retournee: 'Retournée',
    DetailQuote: 'Détail Devis',
    EditQuote: 'Modifier Devis',
    CreateSale: 'Créer une vente',
    DownloadPdf: 'Télécharger le PDF',
    QuoteEmail: 'Envoyé Devis sur Email',
    DeleteQuote: 'Supprimé Devis',
    AddQuote: 'Ajouter Devis',
    SelectProduct: 'Sélectionnez un produit',
    ProductCodeName: 'Produit (Code - Nom)',
    Price: 'Prix',
    CurrentStock: 'Stock',
    Total: 'Total',
    Num: 'N°',
    Unitcost: 'Cout unitaire',
    to: 'à',
    Subject: 'Sujet',
    Message: 'Message',
    EmailCustomer: 'Email Client',
    Sent: 'Envoyer',
    Quote: 'Devis',
    Hello: 'Bonjour',
    AttachmentQuote: 'Veuillez trouver la pièce jointe pour votre devis',
    AddProducts: 'Ajouter les Produits sur la liste des commandes',
    SelectWarehouse: 'Sélectionner un entrepôt',
    SelectCustomer: 'Sélectionner un client',
    SalesManagement: 'Gestion  Des Ventes',
    Balance: 'Balance',
    QtyBack: 'Qté Retour',
    TotalReturn: 'Total Retour',
    MontantReste: 'Montant reste',
    SaleDetail: 'Détail de la vente',
    EditSale: 'Modifier la vente',
    AddSale: 'Ajouter Vente',
    ShowPayment: 'Afficher les paiements',
    AddPayment: 'Ajouter un paiement',
    EditPayment: 'Modifier le paiement',
    EmailSale: 'Envoyer la vente par e-mail',
    DeleteSale: 'Supprimer la vente',
    Amount: 'Montant',
    ModePaiement: 'Mode de paiement',
    Paymentchoice: 'Mode de règlement',
    Note: 'Remarque',
    PaymentComplete: 'Paiement compléte!',
    UserManagement: 'Gestion des utilisateurs',
    Firstname: 'Prénom',
    lastname: 'Nom',
    username: 'utilisateur',
    type: 'Type',
    password: 'Mot de Pass',
    Newpassword: 'Nouveau Mot Pass',
    ChangeAvatar: 'Changer d\'avatar',
    LeaveBlank: 'Veuillez laisser ce champ vide si vous ne l\'avez pas changé',
    UserPermissions: 'Permissions des utilisateurs',
    RoleName: 'Nom Role',
    RoleDescription: 'Discription Role',
    AddPermissions: 'Ajouter des autorisations',
    View: 'Afficher',
    Del: 'Supprimer',
    NewAdjustement: 'Nouvel Ajustement',
    EditAdjustement: 'Modifier Ajustement',
    CannotSubstraction: 'Vous ne pouvez pas soustraire des produits en stock 0',
    Addition: 'Addition',
    Subtraction: 'Soustraction',
    profil: 'Profil',
    logout: 'Se déconnecter',
    PurchaseAlreadyPaid: 'Vous ne pouvez pas modifier car cette Achat a déjà payé',
    SaleAlreadyPaid: 'Vous ne pouvez pas modifier car cette Vente a déjà payé',
    ReturnAlreadyPaid: 'Vous ne pouvez pas modifier car cette Retour a déjà payé',
    QuoteAlready: 'Ce devis a déjà généré une vente',
    QuotationComplete: 'Cette Devis terminée',
    SiteConfiguration: 'Configuration du site',
    Language: 'Langue',
    DefaultCurrency: 'Devise par défaut',
    LoginCaptcha: 'Captcha',
    DefaultEmail: 'Email par défaut',
    SiteName: 'Nom du site',
    ChangeLogo: 'Changer le logo',
    SMTPConfiguration: 'Configuration SMTP',
    HOST: 'HÔTE',
    PORT: 'PORT',
    encryption: 'Chiffrement',
    SMTPIncorrect: 'Configuration SMTP incorrecte',
    ManagementCurrencies: 'Gestion de devises',
    CurrencyCode: 'Code de devise',
    CurrencyName: 'Nom de devise',
    Symbol: 'Symbole',
    CompanyName: 'Nom de la société',
    CompanyPhone: 'Tele de la société',
    CompanyAdress: 'Adresse de la société',
    Code: 'Code',
    image: 'Image',
    Printbarcode: 'Imprimer le code-barres',
    ReturnsCustomers: 'Retours de ventes',
    ReturnsSuppliers: 'Retours d\'achats',
    FactureReturnCustomers: 'Facture Return Clients',
    FactureReturnSuppliers: 'Facture Return Suppliers',
    NodataAvailable: 'Pas de données disponibles',
    ProductImage: 'Image du produit',
    Barcode: 'Code-barre',
    pointofsales: 'Point de vente',
    CustomUpload: 'Téléchargement personnalisé',
    pointofsaleManagement: 'Gestion des points de vente',
    Adjustment: 'Ajustement',
    Updat: 'Mettre à jour',
    Reset: 'Réinitialiser',
    print: 'Impression',
    SearchByEmail: 'Recherche par e-mail',
    ChooseProduct: 'Choisissez un produit',
    Qty: 'Qté',
    Items: 'Articles',
    AmountHT: 'Montant HT',
    AmountTTC: 'Montant TTC',
    PleaseSelectSupplier: 'Veuillez sélectionner un fournisseur',
    PleaseSelectStatut: 'Veuillez sélectionner le statut',
    PayeBy: 'Payé avec',
    ChooseWarehouse: 'Choisissez l\'entrepôt',
    payNow: 'Payez maintenant',
    ListofCategory: 'Liste de la catégorie',
    Description: 'Description',
    submit: 'Soumettre',
    ProblemCreatingThisInvoice: 'Un problème est survenu lors de la création de cette facture. Veuillez réessayer',
    ProblemPayment: 'Il y a eu un problème de paiement. Veuillez réessayer.',
    CreateAdjustment: 'Créer un ajustement',
    Afewwords: 'Quelques mots sur ...',
    UserImage: 'Image utilisateur',
    UpdateProduct: 'Mettre à jour le produit',
    Brand: 'Marque',
    BarcodeSymbology: 'Symbologie des codes à barres',
    ProductCost: 'Prix d\'achat',
    ProductPrice: 'Prix de vente',
    UnitProduct: 'Unité du Produit',
    TaxMethod: 'Méthode fiscale',
    MultipleImage: 'Image multiple',
    ProductHasMultiVariants: 'Le produit a plusieurs variantes',
    ProductHasPromotion: 'Le produit a une promotion',
    PromotionStart: 'Début de la promotion',
    PromotionEnd: 'Fin de la promotion',
    PromotionPrice: 'Prix de la promotion',
    Price: 'Prix',
    Cost: 'Coût',
    Unit: 'Unité',
    ProductVariant: 'Variante de produit',
    Variant: 'Variante',
    UnitPrice: 'Prix unitaire',
    CreateReturnCustomer: 'Créer un retour client',
    EditReturnCustomer: 'Modifier le retour client',
    CreateReturnSupplier: 'Créer le retour fournisseur',
    Documentation: 'Doc',
    EditReturnSupplier: 'Modifier le retour fournisseur',
    FromWarehouse: 'De l\'entrepôt',
    ToWarehouse: 'vers l\'entrepôt',
    EditTransfer: 'Modifier le transfert',
    TransferDetail: 'Détail du transfert',
    Pending: 'En attente',
    Received: 'Reçu',
    Ordered: 'Commandé',
    PermissionsManager: 'Gestion des autorisations',
    BrandManager: 'Gestion de la marque',
    BrandImage: 'Image de marque',
    BrandName: 'Nom de marque',
    BrandDescription: 'Description de la marque',
    BaseUnit: 'Unité de base',
    ManagerUnits: 'Gestion des Unités',
    OperationValue: 'Valeur d\'opération',
    Operator: 'Opération',
    Top5Products: 'Top 5 des produits',
    Last5Sales: '5 dernières ventes',
    ListAdjustments: 'liste des Ajustements',
    ListTransfers: 'Liste des transferts',
    CreateTransfer: 'Créer un transfert',
    OrdersManager: 'Gestion des commandes',
    ListQuotations: 'Liste des quotes',
    ListPurchases: 'Liste des achats',
    ListSales: 'Liste des ventes',
    ListReturns: 'Liste des retours',
    PeopleManager: 'Gestion des personnes',
    ListofBrand: 'Liste des marques',




    // Delete
    Delete: {
        Title: 'Êtes-vous sûr?',
        Text: 'Vous ne pourrez pas revenir en arrière!',
        confirmButtonText: 'Oui, supprimez-le!',
        cancelButtonText: 'Annuler',
        Deleted: 'Supprimé!',
        Failed: 'Échec!',
        Therewassomethingwronge: 'Il y avait quelque chose de mal',
        CustomerDeleted: 'Ce client a été supprimé.',
        CustomerDeleted: 'Cet utilisateur a été supprimé.',
        SupplierDeleted: 'Fournisseur a été supprimé',
        QuoteDeleted: 'Cette Devis a été supprimée.',
        SaleDeleted: 'Cette Vente a été supprimée.',
        PaymentDeleted: 'Cette Paiement a été supprimée.',
        PurchaseDeleted: 'Cette Achat a été supprimée.',
        ReturnDeleted: 'Cette Retour a été supprimée.',
        ProductDeleted: 'Ce produit a été supprimé',
        ClientError: 'Ce Client déja lié avec autre Opération',
        ProviderError: 'Ce fournisseur déja lié avec autre Opération',
        UnitDeleted: 'Cette unités a été supprimé',
        RoleDeleted: 'Ce Role a été supprimé',
        TaxeDeleted: 'Cette Taxe a été supprimé',
        SubCatDeleted: 'Cette sous Categorie a été supprimé',
        CatDeleted: 'Cette Categorie a été supprimé',
        WarehouseDeleted: 'Ce magasin a été supprimé',
        AlreadyLinked: 'Ce produit déja lié avec autre Opération',
        AdjustDeleted: 'Cet Ajustement a été supprimé',
        TitleCurrency: 'Cet Devise a été supprimé',
        TitleTransfer: 'Transfer a été supprimée avec succès',
        BackupDeleted: 'Sauvegarde a été supprimée avec succès',
        TitleBrand: 'Cette marque a été supprimée',

    },

    // Update

    Update: {
        TitleSetting: 'Paramétres mis à jour avec succès',
        TitleProfile: 'Profil mis à jour avec succès',
        TitleAdjust: 'Adjustement mis à jour avec succès',
        TitleRole: 'Role mis à jour avec succès',
        TitleUnit: 'Unités mis à jour avec succès',
        TitleUser: 'Utilisateur mis à jour avec succès',
        TitleProduct: 'Produit mis à jour avec succès',
        TitleCustomer: 'Client mis à jour avec succès',
        TitleQuote: 'Devis mis à jour  avec succès',
        TitleSale: 'Vente mis à jour  avec succès',
        TitlePayment: 'Paiement mis à jour  avec succès',
        TitlePurchase: 'Achat  mis à jour  avec succès',
        TitleReturn: 'Retour  mis à jour  avec succès',
        TitleSupplier: 'Fournisseur  mis à jour  avec succès',
        TitleTaxe: 'Taxe  mis à jour  avec succès',
        TitleCat: 'Categorie mis à jour avec succès',
        TitleWarhouse: 'Magasin mis à jour avec succès',
        TitleCurrency: 'Devise mis à jour avec succès',
        TitleTransfer: 'Transfer mis à jour avec succès',
        TitleBrand: 'Cette marque a été mise à jour',
    },

    // Create

    Create: {
        TitleBrand: 'Cette marque a été créée',
        TitleTransfer: 'Transfer créé avec succès',
        TitleAdjust: 'Adjustement créé avec succès',
        TitleTaxe: 'Taxe créé avec succès',
        TitleRole: 'Role créé avec succès',
        TitleUnit: 'Unités créé avec succès',
        TitleUser: 'Utilisateur créé avec succès',
        TitleCustomer: 'Client créé avec succès',
        TitleQuote: 'Devis créé  avec succès',
        TitleSale: 'Vente créé  avec succès',
        TitlePayment: 'Paiement créé  avec succès',
        TitlePurchase: 'Achat créé  avec succès',
        TitleReturn: 'Retour créé  avec succès',
        TitleProduct: 'Produit créé avec succès',
        TitleSupplier: 'Fournisseur  créé  avec succès',
        TitleCat: 'Categorie  créé  avec succès',
        TitleWarhouse: 'Magasin créé avec succès',
        TitleCurrency: 'Devise créé avec succès',
    },
    Send: {
        TitleEmail: 'Email Envoyé avec succès',
    },
    return: {
        TitleSale: 'Cette vente déja liée avec un Retour!',
    },
    PurchasesManagement: 'Gestion des achats',
    Ordered: 'Commandé',
    DeletePurchase: 'Supprimer l\'achat',
    EmailPurchase: 'Envoyer l\'achat par e-mail',
    EditPurchase: 'Modifier l\'achat',
    PurchaseDetail: 'Détail d\'achat',
    AddPurchase: 'Ajouter d\'achat',
    EmailSupplier: 'Email Fournisseur',
    ReturnManagement: 'Gestion des retours',
    ReturnDetail: 'Détails du retour',
    EditReturn: 'Modifier le retour',
    AddReturn: 'Ajouter le retour',
    EmailReturn: 'Envoyer le retour par e-mail',
    DeleteReturn: 'Supprimer le retour',
    Retoursurcharge: 'Return Surcharge',
    Laivrison: 'Laivrison',
    SelectSale: 'Sélectionnez la vente',
    ZeroPardefault: 'Vous pouvez supprimer l\'élément ou définir la quantité retournée à zéro si elle n\'est pas retournée',
    Return: 'Retour',
    Purchase: 'Achat',
    PurchaseInvoice: 'Paiement des achats',
    PurchasesInvoicesData: 'Données Paiement d\'achat',
    SalesInvoices: 'Paiement des ventes',
    SalesInvoicesData: 'Données Paiement de vente',
    TotalSales: 'Ventes totales',
    TotalPurchases: 'Total des achats',
    TotalReturns: 'Retours totaux',
    PaiementsNet: 'Paiement net',
    PaiementsSent: 'Paiements envoyés',
    PaiementsReceived: 'Paiements reçus',
    Recieved: 'Reçus',
    Sent: 'Envoyés',
    ProductQuantityAlerts: 'Alertes de quantité de produits',
    ProductCode: 'Code produit',
    ProductName: 'Produit',
    AlertQuantity: 'Quantité d\'alerte',
    WarehouseStockChart: 'Graphique du stock d\'entrepôt',
    TotalProducts: 'Total Produits',
    TotalQuantity: 'Total Quantity',
    TopCustomers: 'Top 5 des clients',
    TotalAmount: 'Montant total',
    TotalPaid: 'Total payé',
    CustomerSalesReport: 'Rapport des ventes',
    CustomerPaiementsReport: 'Rapport des paiements',
    CustomerQuotationsReport: 'Rapport des Devis',
    Payments: 'Paiements',
    TopSuppliers: 'Top 5 Providers',
    SupplierPurchasesReport: 'Rapport des achats',
    SupplierPaiementsReport: 'Rapport des paiements',
    Name: 'Nom',
    Code: 'Code',
    ManagementWarehouse: 'Gestion d\'entrepôt',
    ZipCode: 'Code postal',
    managementCategories: 'Gestion des catégories',
    Codecategorie: 'Code Catégorie',
    Namecategorie: 'Name Catégorie',
    Parentcategorie: 'Parente Catégorie',
    managementTax: 'Gestion fiscale',
    TaxName: 'Nom de la taxe',
    TaxRate: 'Taux de taxe',
    managementUnitPurchases: 'Gestion des unités d\'achats',
    managementUnitSales: 'Gestion des unités de vente',
    ShortName: 'Nom court',
    PleaseSelectThesebeforeaddinganyproduct: 'Veuillez les sélectionner avant d\'ajouter un produit',
    StockAdjustement: 'Ajustement des stocks',
    PleaseSelectWarehouse: 'Veuillez les sélectionner magasin avant de choisir un produit',
    StockTransfer: 'Transfert de stock',
    SelectPeriod: 'Sélectionnez la période',
    ThisYear: 'Cette année',
    ThisToday: 'Ce aujourd\'hui',
    ThisMonth: 'Ce mois-ci',
    ThisWeek: 'Cette semaine',
    AdjustmentDetail: 'Détail d\'ajustement',
    ActivateUser: 'Cet utilisateur a été activé',
    DisActivateUser: 'Cet utilisateur a été désactivé',
    NotFound: 'Page non trouvée.',
    oops: 'Erreur! Page non trouvée.',
    couldNotFind: 'Nous n\'avons pas pu trouver la page que vous recherchiez.',
    ReturnDashboard: 'Retour au tableau de bord',


     //hrm module
     hrm:'HRM',
     Employees:'Employés',
     Attendance:'Présence',
     Leave_request:'Demande de congé',
     Leave_type:'Type de congé',
     Company:'Compagnie',
     Departments:'Départements',
     Designations:'Désignations',
     Office_Shift:'Quart de bureau',
     Holidays:'Vacances',
     Enter_Company_Name:'Entrez le nom de l\'entreprise',
     Enter_email_address:'Entrer l\'adresse e-mail',
     Enter_Company_Phone:'Entrer le téléphone de l\'entreprise',
     Enter_Company_Country:'Saisir le pays de l\'entreprise',
     Created_in_successfully:'Créé en avec succès',
     Updated_in_successfully:'Mis à jour avec succès',
     Deleted_in_successfully:'Supprimé dans avec succès',
     department:'Département',
     Enter_Department_Name:'Entrez le nom du département',
     Choose_Company:'Choisissez l\'entreprise',
     Department_Head:'Chef de département',
     Choose_Department_Head:'Choisissez le chef de département',
     Enter_Shift_name:'Entrer le nom du quart',
     Monday_In:'Monday In',
     Monday_Out:'Monday Out',
     Tuesday_In:'Tuesday In',
     tuesday_out:'Tuesday Out',
     wednesday_in:'Wednesday In',
     wednesday_out:'Wednesday Out',
     thursday_in:'Thursday In',
     thursday_out:'Thursday Out',
     friday_in:'Friday In',
     friday_out:'Friday Out',
     saturday_in:'Saturday In',
     saturday_out:'Saturday Out',
     sunday_in:'Sunday In',
     sunday_out:'Sunday Out',
     Holiday:'Vacance',
     Enter_title:'Entrez le titre',
     title:'Titre',
     start_date:'Date de début',
     Enter_Start_date:'Entrez la date de début',
     Finish_Date:'Date de fin',
     Enter_Finish_date:'Entrez la date de fin',
     Please_provide_any_details:'Veuillez fournir des détails',
     Attendances:'Présences',
     Enter_Attendance_date:'Entrez la date de présence',
     Time_In:'Time In',
     Time_Out:'Time Out',
     Choose_Employee:'Choisissez l\'employé',
     Employee:'Employée',
     Work_Duration:'Durée du travail',
     remaining_leaves_are_insufficient:'Les Congés restantes sont insuffisantes',
     Leave_Type:'Type de congé',
     Days:'Jours',
     Department:'Département',
     Choose_leave_type:'Choisissez le type de congé',
     Choose_status:'Choisissez le statut',
     Leave_Reason:'Raison du congé',
     Enter_Reason_Leave:'Entrez la raison du congé',
     Add_Employee:'Ajouter un employé',
     FirstName:'Prénom',
     Enter_FirstName:'Entrez votre prénom',
     LastName:'Nom de famille',
     Enter_LastName:'Entrer le nom de famille',
     Gender:'Le sexe',
     Choose_Gender:'Choisissez le sexe',
     Enter_Birth_date:'Entrez la date de naissance',
     Birth_date:'Date de naissance',
     Enter_Country:'Entrez le pays',
     Enter_Phone_Number:'Entrez le numéro de téléphone',
     joining_date:'Date d\'inscription',
     Enter_joining_date:'Entrez la date d\'adhésion',
     Choose_Designation:'Choisissez la désignation',
     Designation:'La désignation',
     Office_Shift:'Quart de bureau',
     Choose_Office_Shift:'Choisissez le quart de travail',
     Enter_Leaving_Date:'Entrez la date de départ',
     Leaving_Date:'Date de départ',
     Annual_Leave:'Congé annuel',
     Enter_Annual_Leave:'Saisir les congés annuels',
     Remaining_leave:'Congé restant',
     Employee_Details:'Détails de l\'employé',
     Basic_Information:'Information basique',
     Family_status:'Situation familiale',
     Choose_Family_status:'Choisissez le statut familial',
     Employment_type:'Type d\'emploi',
     Select_Employment_type:'Sélectionnez le type d\'emploi',
     Enter_City:'Entrez la ville',
     Province:'Province',
     Enter_Province:'Entrez la province',
     Enter_Address:'Entrer l\'adresse',
     Enter_Zip_code:'Entrer le code postal',
     Zip_code:'Code postal',
     Hourly_rate:'Tarif horaire',
     Enter_Hourly_rate:'Entrez le taux horaire',
     Basic_salary:'Salaire de base',
     Enter_Basic_salary:'Entrez le salaire de base',
     Social_Media:'Réseaux sociaux',
     Skype:'Skype',
     Enter_Skype:'Entrez Skype',
     Facebook:'Facebook',
     Enter_Facebook:'Entrez Facebook',
     WhatsApp:'WhatsApp',
     Enter_WhatsApp:'Entrez WhatsApp',
     LinkedIn:'LinkedIn',
     Enter_LinkedIn:'Entrez LinkedIn',
     Twitter:'Twitter',
     Enter_Twitter:'Entrez Twitter',
     Experiences:'Expériences',
     bank_account:'compte bancaire',
     Company_Name:'Nom de l\'entreprise',
     Location:'l\'emplacement',
     Enter_location:'Entrez l\'emplacement',
     Enter_Description:'Entrez la description',
     Bank_Name:'Nom de banque',
     Enter_Bank_Name:'Entrez le nom de la banque',
     Bank_Branch:'Agence bancaire',
     Enter_Bank_Branch:'Entrer l\'agence bancaire',
     Bank_Number:'Numéro de banque',
     Enter_Bank_Number:'Entrez le numéro de banque',
     Assigned_warehouses:'Entrepôts affectés',
     Top_customers:'meilleurs clients',
     Attachment:'Attachement',
     view_employee:'voir les employés',
     edit_employee:'modifier les employés',
     delete_employee:'supprimer des employés',
    Created_by : 'Ajouté par',
    Add_product_IMEI_Serial_number:'Ajouter le produit IMEI/numéro de série',
    Product_Has_Imei_Serial_number:'Le produit a un numéro IMEI/série',
    IMEI_SN:'IMEI/SN',
    Shipments:'Expéditions',
    delivered_to:'Livré à',
    shipment_ref:'Réf d\'expédition',
    sale_ref:'Réf vente',
    Edit_Shipping:'Modifier la livraison',
    Packed:'Emballé',
    Shipped:'Expédié',
    Delivered:'Livré',
    Cancelled:'Annulé',
    Shipping_status:'Statut d\'envoi',
    Users_Report:'Rapport des utilisateurs',
    stock_report:'Rapport d\'inventaire',
    TotalPurchases:'Total des achats',
    Total_quotations:'Total des devis',
    Total_return_sales:'Total des retours de vente',
    Total_return_purchases:'Total des retours d\'achat',
    Total_transfers:'Total des transferts',
    Total_adjustments:'Total des ajustements',
    User_report:'Rapport d\'utilisateur',
    Current_stock:'Stock actuel',
    product_name:'Nom du produit',
    Total_Customers_Due:'Dette totale',
    Total_Suppliers_Due:'Dette totale',
    Some_warehouses:'Certains entrepôts',
    All_Warehouses:'Tous les entrepôts',
    Product_Cost:'Prix d\'achat',
    sms_settings:'Paramètres SMS',
    pos_settings:'Paramètres du point de vente',
    payment_gateway:'Passerelle de paiement',
    mail_settings:'Paramètres de messagerie',
    Nexmo_SMS:'Nexmo SMS',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'Passerelle SMS par défaut',
    module_settings:'Paramètres des modules',
    Module_enabled_success:'Module activé avec succès',
    Module_Disabled_success:'Module désactivé avec succès',
    update_settings:'Mettre à jour les paramètres',
    Please_Upload_the_Correct_Module:'Veuillez télécharger le bon module',
    Uploaded_Success:'Importé avec succès',
    Customer_details:'Détails du client',
    Edit_Customer:'Modifier le client',
    Delete_Customer:'Supprimer le client',
    Pay_Due:'payer dû',
    Paying_amount_is_greater_than_Total_Due:'Le montant du paiement est supérieur au total dû',
    Customer_Invoice:'Facture client',
    This_Product_Not_For_Selling:'Ce produit n\'est pas à vendre',
    Welcome_to_your_Dashboard:'Bienvenue sur votre tableau de bord',
    Total_Payable:'Total à payer',
    Choose_Sale_Ref:'Choisissez la référence de la vente',
    Please_Select_Sale:'Veuillez sélectionner la vente',
    Sale_Ref:'Réf vente',
    list_product_returns:'list des retours de produits',
    Qty_return:'Qté de retour',
    Choose_Purchase_Ref:'Choisissez la référence d\'achat',
    Purchase_Ref:'Réf d\'achat',
    please_select_purchase:'Veuillez sélectionner l\'achat',
    qty_return_is_greater_than_qty_purchased:'La quantité retournée est supérieure à la quantité achetée',
    qty_return_is_greater_than_qty_sold:'La quantité retournée est supérieure à la quantité vendue',
    Quantity_sold:'Qté vendue',
    qty_purchased:'Qté achetée',
    Sender_Name:'Nom expéditeur',
    The_module_must_be_uploaded_as_zip_file:'Le module doit être téléchargé sous forme de fichier zip',
    Upload_Module:'Télécharger le module',
    All_Modules_Installed:'Tous les modules installés',
    Current_Version:'Version actuelle',
    You_already_have_the_latest_version:'Vous avez déjà la dernière version',
    Update_Available:'Mise à jour disponible',
    Update_Log:'Journal de mise à jour',
    Update_Now:'Mettez à jour maintenant',
    invoice_footer:'Pied de facture',
    View_Change_Log:'Voir la log des modifications',
    Please_Wait_for_the_update :'REMARQUE : Veuillez attendre que la mise à jour se termine complètement. Il n\'est pas recommandé de fermer le navigateur ou d\'arrêter un processus de mise à niveau',
    Note_update :'Remarque : Assurez-vous de sauvegarder votre version actuelle et votre base de données avant d\'exécuter la mise à niveau, pour la restaurer en cas d\'erreur.',
    Time_Zone:'Fuseau horaire',
    Payment_note:'Paiement Remarque',
    sale_note:'notes de vente',
    Total_Sale_Due:'Vente totale dû',
    Total_Sell_Return_Due:'Retour de vente total dû',
    pay_all_sell_due_at_a_time:'payer toutes les dettes de vente à la fois',
    pay_all_sell_return_due_at_a_time:'payer toutes les dettes de retour de vente à la fois',
    Gross_Profit:'Bénéfice brut',
    pay_all_purchase_due_at_a_time:'payer toutes les dettes d\'achat à la fois',
    Delete_Provider:'Supprimer le fournisseur',
    Edit_Provider:'Modifier le fournisseur',
    Provider_details:'Détails du fournisseur',
    Total_Purchase_Due:'Dette d\'achat totale',
    Provider_Credit_Note:'Note de crédit',
    Customer_Credit_Note:'Note de crédit',
    Sell_Return:'Retour de vente',
    Purchase_Return:'Retour d\'achat',
    Tax_Number:'Numéro de TVA',
    Please_add_return_quantity:'Veuillez ajouter la quantité de retour',
    Return_exist_for_the_Transaction:'Le retour existe pour la transaction',
    qty_return_is_greater_than_Quantity_Remaining:'La quantité retournée est supérieure à la quantité restante',
    products_refunded_alert:'Tous les produits dont la quantité est définie sur 0 ne seront pas remboursés',
    pay_all_purchase_return_due_at_a_time:'payer tous les retours d\'achat dus à la fois',
    Total_Purchase_Return_Due:'Total de la dette de retour d\'achat',
    Purchase_return_due:'Retour d\'achat dû',
    Sell_return_due:'Retour de vente dû',
    product_report:'Rapport de produit',
    product_sales_report:'Rapport de vente de produits',
    Product_purchases_report:'Rapport d\'achat de produits',
    Qty_sold:'Qté vendue',
    Qty_purchased:'Qté achetée',
    Filter_by_warehouse:'Filtrer par magasin',
    Enable_Print_Invoice:'Imprimer la facture automatiquement',
    Show_Warehouse:'voir l\'entrepôt',

    credit_card_info: 'Informations de carte de crédit',
    Saved_Credit_Card_Info: 'Informations de carte de crédit enregistrées',
    Credit_card_changed_successfully: 'Carte de crédit changée avec succès',
    notification_template: 'Modèle de notification',
    email_notification: 'Notification par email',
    sms_notification: 'Notification par SMS',
    Change_product_details: 'Modifier les détails du produit',
    edit_tax_and_discount_and_shipping: 'Modifier les taxes, remises et livraison',
    Create_Quotation_with_Stock: 'Créer une citation avec stock',
    Variant_Name: 'Nom de la variante',
    Variant_cost: 'Coût de la variante',
    Variant_price: 'Prix de la variante',
    Variant_code: 'Code de la variante',
    Please_wait_until_the_product_is_loaded: 'Veuillez patienter jusqu\'à ce que le produit soit chargé',
    Choose_SMS_Gateway: 'Choisir la passerelle SMS',
    Notification_Client: 'Notification au client',
    Available_Tags: 'Tags disponibles',
    Email_Subject: 'Objet de l\'email',
    Email_body: 'Corps de l\'email',
    Notification_Supplier: 'Notification au fournisseur',
    sms_body: 'Corps du SMS',
    sms_templates:'Modèles de SMS',
    email_templates:'Modèles d\'e-mails',


    ///----new
    Color : 'Couleur' ,
    Status_Name : 'Nom du statut' ,
    Status_Code : 'Code du statut' ,
    Status_Color : 'Couleur du statut' ,
    Enter_Name_Status : 'Entrez le nom du statut' ,
    Enter_Code_Status : 'Entrez le code du statut' ,
    Enter_Color_Status : 'Entrez la couleur du statut' ,
    Repair_Status : 'Statut de réparation' ,
    Repair_Managements : 'Gestions de réparation',
    Repair_Categories : 'Catégories de réparation',
    Repair_Category : 'Catégorie de réparation',
    CategoryName : 'Nom de la catégorie',
    Icon : 'Icône',
    Enter_Name_RepairCategory : 'Entrer le nom de la catégorie',
    Enter_Code_RepairCategory : 'Entrer le code de la catégorie',
    Enter_Icon_RepairCategory : 'Entrer l\'icône de la catégorie',
    Brand_Model : 'Marque Modèle',
    Brand_Models : 'Marques Modèles',
    BrandModelName : 'Nom de la marque modèle',
    Enter_Name_BrandModel : 'Entrer le nom de la marque modèle',
    BrandModelDescription : 'Description de la marque modèle',
    Enter_Description_BrandModel : 'Entrer la description de la marque modèle',
    BrandModelImage : 'Image de la marque modèle',
    Brand_Model_Devices : 'Appareils de la marque modèle',
    Brand_Model_Device : 'Appareil de la marque modèle',
    Choose_BrandModel : 'Choisir la marque modèle',
    BrandModelDeviceName : 'Nom de l\'appareil',
Enter_Name_BrandModelDevice : 'Entrer le nom de l\'appareil',
BrandModelDeviceDescription : 'Description de l\'appareil',
Enter_Description_BrandModelDevice : 'Entrer la description de l\'appareil',
BrandModelDeviceImage : 'Image de l\'appareil',
Devices_Maintenances : 'Maintenances des appareils',
    BrandModelDeviceMaintenances : 'Maintenances des appareils',
    BrandModelDeviceMaintenance  : 'Maintenance de l\'appareil',
Enter_maintenance_BrandModelDevice  : 'Entrer la maintenance de l\'appareil',
Choose_Brand_Model_Device  : 'Choisir l\'appareil',
BrandModelDeviceItemCost  : 'Coût',
    Enter_cost_BrandModelDevice  : 'Entrer le coût',
    RepairTax  : 'Taxe de réparation',
    UsedProducts  : 'Produits usagés',
    Add_Repair_Request  : 'Ajouter une demande de réparation',
    Edit_Repair_Request  : 'Modifier la demande de réparation',
    Repair_Request_List  : 'Liste des demandes de réparation',
    Please_Select_Device  : 'Veuillez sélectionner l\'appareil',
ClientNote  : 'Note du client',
    MaintenanceNote  : 'Note de maintenance',
    RepairRequests  : 'Demandes de réparation',

    Create_2 : {
    TitleRepairStatus: 'Statut de réparation créé avec succès',
        TitleRepairCategory: 'Catégorie de réparation créée avec succès',
        TitleBrandModel: 'Marque modèle créée avec succès',
        TitleBrandModelDevice: 'Appareil créé avec succès',
        TitleBrandModelDeviceItem: 'Maintenance de l\'appareil créée avec succès',
    TitleRepairRequest: 'Demande de réparation créée avec succès',
},
Update_2 : {
    TitleRepairStatus: 'Statut de réparation mis à jour avec succès',
        TitleRepairCategory: 'Catégorie de réparation mise à jour avec succès',
        TitleBrandModel: 'Marque modèle mise à jour avec succès',
        TitleBrandModelDevice: 'Appareil mis à jour avec succès',
        TitleBrandModelDeviceItem: 'Maintenance de l\'appareil mise à jour avec succès',
    TitleRepairRequest: 'Demande de réparation mise à jour avec succès',
},
Delete_2 : {
    TitleRepairStatus: 'Ce statut a été supprimé',
        TitleRepairCategory: 'Cette catégorie a été supprimée',
        TitleBrandModel: 'Cette marque modèle a été supprimée',
        TitleBrandModelDevice: 'Cet appareil a été supprimé',
        TitleBrandModelDeviceItem: 'Cette maintenance d\'appareil a été supprimée',
    TitleRepairRequest: 'Cette demande de réparation a été supprimée',

},
Shipment_Request:'Demande d\'expédition',
//-------------------------
    Edit_Repair_Request_Status: "Modifier le statut de la demande de réparation",
    Repair_Request_Status: "Statut de la demande de réparation",
    Repair_Request_Payment: "Paiements reçus pour les demandes de réparation",
    SMS: "SMS",
    EMAIL: "Email",
    Maintenance: "Maintenance",
    SelectRepairMaintenance: "Sélectionner la maintenance de l'appareil",
    direct_repair: "Réparation directe",
    edit_maintenance_repair: "Modifier la maintenance (Réparation directe)",
    there_is_not_repairs_request: "Il n'y a pas de demandes de réparation",
    repair_dashboard: "Tableau de bord (Réparations)",
    today: "Aujourd'hui",
    yesterday: "Hier",
    last_seven_days: "Les sept derniers jours",
    current_month: "Mois en cours",
    last_month: "Mois dernier",
    current_year: "Année en cours",
    last_year: "Année dernière",
    //------------------
    "Rate": "Tarif",
    "warranty": "Garantie",
    "enter_imei_number": "Entrez le numéro IMEI",
    "enter_warranty_in_month": "Entrez la garantie en mois",
    "months": "Mois",
    "View_Completed": "Voir Terminé",
    "mr": "M.",
    "mrs": "Mme.",
    "ms": "Mlle.",
    "person": "Personne",
    "company": "Société",
    "personOrCompany": "Personne/Société",
    "Street": "Rue",
    "PostCode": "Code Postal",
    "Filter_by_Date_Period": "Filtrer par Période",
    "repair_appointments": "Rendez-vous de réparation",
    "time": "Temps",
    "New": "Nouveau",
    "APPROVE": "Approuver",
    "APPROVED": "Approuvé",
    "Repair_Appointment_has_been_approved": "Ce rendez-vous de réparation a été approuvé",
    "Approve_Repair_Appointment": "Approuver le rendez-vous de réparation",
    "CANCEL": "Annuler",
    "CANCELED": "Annulé",
    "Repair_Appointment_has_been_canceled": "Ce rendez-vous de réparation a été annulé",
    "Cancel_Repair_Appointment": "Annuler le rendez-vous de réparation",
    "DONE": "Fait",
    "LONGITUDE": "Longitude",
    "LATITUDE": "Latitude",
    "TEILE": "Pièces",
    "ACCESSOIRES": "Accessoires",
    "DIVERS": "Divers",
    "ORIGINAL": "Original",
    "PREMIUM": "Premium",
    "SUPER_COPY": "Super Copie",
    "PRODUCT_TYPE": "Type de produit",
    "Choose_Product_Type": "Choisissez le type de produit",
    "QUALITY": "Qualité",
    "Choose_Quality": "Choisissez la qualité",
    "Enter_Color": "Entrez la couleur",
    "Order": "Commande",
    "Enter_Order": "Entrez la commande",
    "ActivateCategory": "Cette catégorie a été activée",
    "DisActivateCategory": "Cette catégorie a été désactivée",
    "Please_wait_until_the_maintenance_is_loaded": "Veuillez attendre que la maintenance soit chargée",
    "customs_cost": "Coût douanier",
    "shipping_cost": "Coût d'expédition",
    "taxes_cost": "Coût des taxes",
    "customs_fee": "Frais de douane",
    "shipping_fee": "Frais d'expédition",
    "taxes_fee": "Frais de taxes",
    "supplier_code": "Code fournisseur",
    "Cost_Before": "Coût Avant",
    "Select_customer": "Veuillez sélectionner un client",
    //------------------

    Note_to_company:'Note pour l\'entreprise',
    insurance_company:'Compagnie d\'assurance',
    company_name:'Nom de l\'entreprise',
    inspectable_parts: 'Pièces inspectables',
    device_status: 'État de l\'appareil',
    device_status_descriptions: 'Descriptions de l\'état de l\'appareil',

    Enter_Name_Insurance_company: 'Entrez le nom de la compagnie d\'assurance',
    TitleInsuranceCompany: 'Compagnie d\'assurance',
    Delete_Insurance_companyDeleted: 'Cette compagnie d\'assurance a été supprimée',
    Create_TitleInsuranceCompany: 'Cette compagnie d\'assurance a été créée',
    Update_TitleInsuranceCompany: 'Cette compagnie d\'assurance a été mise à jour',

    Enter_Name_InspectablePart: 'Entrez le nom de la pièce inspectable',
    Delete_TitleInspectablePart: 'Cette pièce inspectable a été supprimée',
    Update_TitleInspectablePart: 'Cette pièce inspectable a été mise à jour',
    Create_TitleInspectablePart: 'Cette pièce inspectable a été créée',

    Enter_status_deviceStatusDescription: 'Entrez la description de l\'état de l\'appareil',
    Delete_TitleDeviceStatusDescription: 'Cette description d\'état a été supprimée',
    Update_TitleDeviceStatusDescription: 'Cette description d\'état a été mise à jour',
    Create_TitleDeviceStatusDescription: 'Cette description d\'état a été créée',
    RepairStatistics: 'Statistiques de réparation',
    insurance_number: 'Numéro d\'assurance',
    enter_insurance_number: 'Entrez le numéro d\'assurance',
    insurance_damage_number: 'Numéro de sinistre',
    enter_insurance_damage_number: 'Entrez le numéro de sinistre',
    Choose_insurance_company: 'Choisissez la compagnie d\'assurance',
    ClientDescription: 'Description du client',
    UserDescription: 'Description de l\'utilisateur',
    enter_password: 'Entrez le mot de passe',
    Work: 'Fonctionne',
    NotWork: 'Ne fonctionne pas',
    due_date: 'Date d\'échéance',
    Frame_condition: 'État du cadre',
    Scratch_free: 'Sans rayure',
    Scratched: 'Rayé',
    right: 'Droite',
    left: 'Gauche',
    top: 'Haut',
    bottom: 'Bas',
    Add_Invoice: 'Ajouter une facture',
    RepairRequestDetail: 'Détails de la demande de réparation',
    RepairRequest: 'Demande de réparation',
    Device_info: 'Info appareil',
    NoClientDescription: 'Pas de description client',
    NoUserDescription: 'Pas de description utilisateur',
    RepairStatus: 'État de la réparation',
    RepairStatusLog: 'Historique de l\'état de réparation',
    Invoice_Receive: 'Réception de facture',
    estimated_cost: 'Coût estimé',
    Choose_Date_Period: 'Choisir la période',
    update_products_price: 'Mettre à jour le prix',
    import_products_price: "Importer les prix des produits",
    net: 'Net',

    //------------------

};
